import { useState, useEffect } from 'react';

export const useNavMagic = () => {
  let [navHeight, setNavHeight] = useState(87);

  useEffect(() => {
    setTimeout(() => {
      let height = document.getElementById("nav")?.offsetHeight;

      if (height !== undefined && height != null) {
        setNavHeight(height);
      }
    }, 300);
  }, []);

  return { navHeight };
};