import { useState } from 'react'
import { ITag } from './service/dto.types'
import { Style } from "./Globals"

type Props = {
  //filterTrips: (value: ITag[]) => void,
  tagList: ITag[],
  setTagList: (ITagList : ITag[]) => void,
}
const TripFilter = ({ tagList, setTagList } : Props) => {
  const [tags, setTags] = useState<ITag[]>(tagList)

  const updateTags = (t: ITag[]) => {
    setTags(t)
    setTagList(t)
  }

  /* Totally selected Tags */
  const totalSelected = tags.reduce((totalSelected, tag) => {
      if (tag.isActive) totalSelected++
      return totalSelected
    }, 0);

  return (
    (tags && tags.length > 0) ? 
      <div className="container m-auto px-4">
        <div className="flex flex-wrap items-start mt-12 items-center justify-center">
          <div className={`lg:basis-1/2 p-4 flex flex-wrap justify-center text-center md:text-left mb-2`}>
            {
              tags.reduce((acc, curr) => {
                if(acc.some(t => t.name === curr.name)){
                  return acc
                } else {
                  return [...acc, curr]
                }
              }, [] as ITag[]).map((tag) => {
                return <button key={tag.name} onClick={() => {
                  const t = tags.map((t) => {
                    if (t.name === tag.name) {
                      t.isActive = !t.isActive
                    }
                    return t
                  })
                  
                  updateTags(t)
                }} className={`${tag.isActive ? "text-white bg-indigo-900" : "text-indigo-900"}
                  text-md inline-blockpy-1 px-2 mr-2 mb-2 rounded-md border-2 border-indigo-900 tracking-wider hover:bg-slate-100`}>
                  {tag.name}
                </button>
              })
            }
          </div>
          <div className="basis-full text-lg text-center">
            {
              tags.filter(t => t.isActive).length > 0 &&
              <div>
                <div className='inline-block'>
                  Sie haben <span className={Style.TextHighlight}>{totalSelected} {totalSelected < 2 ? " Reisetyp" : " Reisetypen"}</span> ausgewählt.
                </div>
                <br />
                <button className={`inline-block mt-1 ${Style.TextColor} underline hover:no-underline`} onClick={() => updateTags(tags.map(t => { return { ...t, isActive: false } }))}>Zurücksetzen</button>
              </div>
            }
          </div>

        </div>
      </div>
      : <></>
  )
}

export default TripFilter