interface Props {
  message: string
}

const Loading = (props: Props) => {
  return (
    <div>
      <div className="flex flex-col flex-wrap justify-center items-center min-h-[20rem] animate-pulse">
        <div>
          {props.message}
        </div>
        <div>
          <div className="w-16">
            <svg className="text-center" version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 100 50" enableBackground="new 0 0 0 0">
              <circle fill="currentColor" stroke="none" cx="25" cy="25" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="currentColor" stroke="none" cx="50" cy="25" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="currentColor" stroke="none" cx="75" cy="25" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Loading