import { useEffect } from 'react'
import { Outlet, useOutletContext, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { SessionContextType } from '../service/app.types'
import Login from './Login'
import Headline from './Headline'
import * as ApiService from '../service/api.service'
import "../../App.css"
import { toast } from 'react-toastify'

import useToken from '../service/auth.service';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  // Admin, initialize Token
  const { token, setToken, logout } = useToken();
  const location = useLocation()

  useEffect(() => {
    const checkToken = (token: string) => {
      ApiService.checkToken(token).catch(() => {
        // token is not valid anymore
        logout()
        toast.warning("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
      })
    }  

    if(token){
      checkToken(token)
      //const checkInterval = setInterval(() => checkToken(token), 1000 * 60) // every minute

      return(() => {
        //clearInterval(checkInterval)      
      })
    } 
  }, [location, token, logout])

  return (
    <div>
      <ToastContainer />
      {
        token ?
          <div>
            <Headline logout={logout} />
            <Outlet context={{ token, setToken, logout }} />
          </div> : <Login setToken={setToken} />
      }
    </div>

  )
}

export function useSession() {
  return useOutletContext<SessionContextType>()
}

export default App