import { ReactComponent as Loader } from '../icons/loader.svg'

const SubmitButton = ({ text, className, loading, disabled } : any) => {
  return (
    <button 
      type="submit" 
      className={className}
      disabled={disabled}>
        {!loading ? text : <Loader className="spinner" />}
    </button>

  )
}

export default SubmitButton