import { ReactComponent as Radio } from "../icons/radio-button-svgrepo-com.svg"

type Props = {
  isActive?: boolean,
  label: string,
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
  value: React.ButtonHTMLAttributes<HTMLButtonElement>['value'],
  activeColor: string,
}

const RadioButton = ({ isActive = false, ...props } : Props) => {
  return (
    <button value={props.value} onClick={props.onClick} type="button" className='group flex flex-row items-center text-left my-2'>
      <Radio className={`pointer-events-none w-6 h-6 mr-2 my-2 ${isActive ? props.activeColor : `text-gray-400 group-hover:text-gray-500`}`} />
      { props.label }
    </button>)
}

export default RadioButton