import { MouseEvent, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { useForm } from "react-hook-form";

import { toast } from 'react-toastify';

import { News } from '../../service/dto.types';
import * as NewsService from '../../service/news.service'
import * as ApiService from '../../service/api.service'

import SubmitButton from '../../ui/buttons/ButtonSubmit'

import { useSession } from "./Container";

import { Style } from '../../Globals'

// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
registerLocale("de", de);

const NewNews = () => {

  const { id: newsId } = useParams();
  const { token, logout } = useSession();

  // State
  const [attachments, setAttachments] = useState<FileList>()
  const attachmentsList = attachments ? [...attachments] : [];
  const [showLoader, setShowLoader] = useState(false)
  const [returnToOverview, setReturnToOverview] = useState(false)

  // Initialize News
  const [news, setNews] = useState<News>(NewsService.getNewNews())
  const [isNewNews, setIsNewNews] = useState(true)

  // React router
  const navigate = useNavigate();
  
  // Form Validation
  const form = useForm();
  
  useEffect(() => {
    if (newsId) { 
      try{
        // Fetch news
        ApiService.fetchNewsById(newsId).then(news => {
          if(!news) { throw new Error() }

          // Parse news and save state
          setNews(NewsService.parseNews(news))
          form.reset()
          setIsNewNews(false)
        })
      } catch(error: any){
        toast.error("Fehler beim Laden der News")
        setReturnToOverview(true)
      }
    } else {
      // Save new news state
      setNews(NewsService.getNewNews())
      setIsNewNews(true)
    }
  }, [newsId, form])

  // Handle field change
  const handleFieldChange = (event: any) => {
    if (news) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      setNews({ ...news, [name]: value });
    }

    form.clearErrors()
  }

  const addAttachmentsToNews = async (newsId: string) => {

    try{
      attachmentsList.forEach((file) => {
        const attachmentsFormData = new FormData()
        // Add the attachments to the form data
        
        attachmentsFormData.append("attachment", JSON.stringify({ name: file.name}))
        attachmentsFormData.append("file", file)
        // Add attachments to existing news via API call

        const data = ApiService.addAttachmentsToNews(newsId, attachmentsFormData, token)
        if(!data) throw Error ("Fehler beim hinzufügen eines Anhangs")
      });

      toast.success("News wurde erfolgreich bearbeitet")
      navigate("/admin/news")

    }catch(error: any){
      toast.error(error.message || "Fehler beim hinzufügen der Anhänge")
    }  
  } 

  // Handle submit of news
  const onSubmit = async () => {
    // Create the form data
    const formData = new FormData();
    formData.append('news', JSON.stringify({
      id: news.id,
      title: news.title,
      description: news.description,
      creation_date: news.creation_date,
    }));

    // Check auth
    try{
      const authenticated = await ApiService.checkToken(token)
      if (!authenticated) throw Error ("Sitzung abgelaufen")
    }catch(error: any){
      logout()
      toast(error.message || "Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.") 
    }

    setShowLoader(true)

    if (!isNewNews && newsId) {
      try{
        const data = await ApiService.updateNews(newsId.toString(), formData, token)
        if(!data) throw Error ("News konnte nicht bearbeitet werden")
        
        // Add attachments
        if (attachmentsList.length > 0) {
          // Add attachments
          addAttachmentsToNews(newsId.toString())
        }else{
          toast.success("News wurde erfolgreich bearbeitet")
          navigate("/admin/news")
        }
      } catch(error: any){
        toast.error(error.message || "News konnte nicht bearbeitet werden")
      } finally {
        setShowLoader(false)
      }
    } else {
      // Create new news

      ApiService.createNews(formData, token)
      .then(data => {
        if(!data || !data.id) throw Error ("News konnte nicht erstellt werden")

        // Add attachments
        addAttachmentsToNews(data.id.toString())
      }).catch(error => {
        toast.error(error.message || "News konnte nicht erstellt werden")
      }).finally(() => {
        setShowLoader(false)
      })
    }
  }

  const handleDeleteAttachment = async (event: MouseEvent) => {
    const target = (event.currentTarget as HTMLInputElement)
    const id = target.getAttribute("data-id")

    // Check Auth
    try{
      const authenticated = await ApiService.checkToken(token)
      if(!authenticated) throw new Error()

    } catch(error: any){
      toast("Sitzung abgelaufen! Sie werden ausgeloggt.", { type: "error" })
      logout()
    }

    try{
      if(!id || !news.id) throw new Error("Fehler! Id fehlt.")

      ApiService.deleteNewsAttachment(news.id, parseInt(id), token)
      .then(() => {
        toast.success("Anhang erfolgreich gelöscht.")
        setNews({ ...news, attachments: news.attachments?.filter(attachment => attachment.id !== parseInt(id)) })
      })
    } catch(error: any){
      toast.error(error.message || "Fehler beim Löschen des Anhangs.")
    }
  }

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachments(event.target.files ? event.target.files : new FileList())
  }

  return (
    returnToOverview ? navigate("/admin/news") : 
    <div>
      <h2 className='text-2xl'>{!isNewNews ? "News bearbeiten" : "Neue News erstellen"}</h2>
      <div className="w-full mt-4">
        <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="form-group mb-4">
            <label htmlFor="title" className={Style.Label}>Titel</label>
            <input type="text" 
              {...form.register("title", { required: true })}
              className={Style.Input}
              id="title" 
              placeholder="Bitte geben Sie eine Überschrift zur News ein"
              value={news.title}
              defaultValue={news.title}
              onChange={handleFieldChange}
            />

            {form.formState.errors.title && <span className={Style.ErrorText}>Bitte geben Sie einen Titel ein</span>}
          </div>

          <div className="form-group mb-4">
            <label htmlFor="description" className={Style.Label}>Beschreibung</label>

            <textarea {...form.register("description", { required: true })} 
              className={Style.Input} 
              id="description" 
              placeholder="Bitte geben Sie eine kurze Beschreibung ein" 
              value={news.description}
              defaultValue={news.description}
              onChange={handleFieldChange}
            />
            {form.formState.errors.description && <span className={Style.ErrorText}>Bitte geben Sie einen Newsbeschrieb ein (zwei, drei Kurze Sätze)</span>}

          </div>

          <div className="form-group mb-4">
            <h2>Anhänge</h2>
            <div className="flex flex-wrap">
                {
                  // Map over savedAttachments and display them 
                news.attachments && news.attachments.length > 0 ?
                    <ul className=''>
                      { 
                      news.attachments.map((attachment) => {
                          return (
                          <li key={attachment.id} className="flex items-stretch m-4 ">
                            <a href={attachment.url}>{attachment.name}</a>
                            <div className='pl-4 ml-auto'>
                            <button type="button" className='bg-red-600/75 hover:bg-red-800 aspect-square inline-block' onClick={handleDeleteAttachment} data-id={attachment.id}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0,0,16,16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                              </svg>
                            </button>
                            </div>
                          </li>
                          )
                        }) 
                      }
                    </ul> : <p className='italic pb-4'>Noch keine Anhänge gespeichert!</p>
                }
                
            </div>
            <label htmlFor="attachments" className={Style.Label}>Anhänge</label>
            <input type="file" 
              className={Style.Input} 
              id="attachments" 
              name="attachments"
              accept="pdf" 
              onChange={handleAttachmentChange} 
              multiple/>
          </div>

          <SubmitButton
            text={!isNewNews ? "Änderungen speichern" : "News erstellen"}
            loading={showLoader}
            disabled={showLoader}
            className={`${Style.Button} disabled:bg-slate-200 disabled:cursor-not-allowed`}
          />
        </form>
      </div>
    </div>
  )
}

export default NewNews
