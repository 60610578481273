import { Trip } from "../../service/dto.types"
import { renderThumb } from "../service/trip.service"
import { getDateRange, isVoranzeige as getIsVoranzeige } from "../../service/trip.service"
import { Link } from "react-router-dom"

type Props = {
  trip: Trip,
  handleDeleteTrip: (tripId: number) => void,
}

const TripListItem = ({trip, handleDeleteTrip} : Props) => {

  const isVoranzeige = getIsVoranzeige(trip)

  return (
    <div className='flex flex-wrap items-stretch justify-left items-center bg-slate-200 mb-4' key={trip.id}>
      <div className="md:mr-4 basis-full md:basis-auto">
        {
          renderThumb(trip)
        }
      </div>
      <div className="p-4">
        <h3 className='font-bold'>{ isVoranzeige && `[VORANZEIGE]`} {trip.title}</h3>
        <p>{!isVoranzeige && getDateRange(trip)}</p>
      </div>
      <div className="ml-auto flex flex-row items-stretch text-white text-sm font-bold">

        <Link to={`/admin/trips/${trip.id}/edit`} state={{ data: trip, edit: true }}
          className='grow-0 flex basis-1/2 items-center py-2 px-4 bg-gray-800 hover:bg-gray-600 shrink'>
            Bearbeiten
        </Link>

        {
          // Delete
          <button className='shrink-0 basis-1/2 bg-red-800 hover:bg-red-600 py-2 px-4'
            onClick={() => { trip.id && handleDeleteTrip(trip.id) }}>
              Löschen
          </button>
        }
      </div>
    </div>
  )
}

export default TripListItem