import NavMagicContainer from "../NavMagicContainer"
import "./style.scss"

import ReactMarkdown from 'react-markdown'

const PrivacyPolicy = () => {

  const CONSTANTS = {
    firma: "A.N.K. - Tours",
    adresse: "Gerberstrasse 9, 4410 Liestal",
    telefon: "+41 (0) 61 951 24 24",
    email: "info@ank-tours.ch",
    domain: "ank-tours.ch",
    hoster_firma: "DigitalOcean, LLC.",
    hoster_adresse: "101 6th Ave New York, NY 10013",
    hoster_url_datenschutz: "https://www.digitalocean.com/legal/privacy-policy/",
  }

  const policyText = `
  1. Datenschutzerklärung

      Die vorliegende Datenschutzerklärung klärt Sie über die Art, den Umfang und den Zweck der Erhebung und Verwendung 
      personenbezogener Daten auf unserer Website ${CONSTANTS.domain} (im Folgenden „Website“) durch die Firma ${CONSTANTS.firma} 
      (im Folgenden „wir“ oder „uns“) auf und gibt über die Ihnen zustehenden Rechte Auskunft. 
      Diese Rechte richten sich nach den anwendbaren Datenschutzgesetzen.

  2. Verantwortlicher für die Datenbearbeitung

      Verantwortlicher für die Datenbearbeitung auf dieser Website und Ansprechpartner für Datenschutzanliegen ist:
    
      ${CONSTANTS.firma}
    
      ${CONSTANTS.adresse}
    
      ${CONSTANTS.telefon} 
    
      ${CONSTANTS.email}  
  
  3. Datenerfassung auf dieser Website

      1. Logfiles

            Unsere Website wird von 
            ${CONSTANTS.hoster_firma}
            (${CONSTANTS.hoster_adresse})
            gehostet. 

            Zur Optimierung und Aufrechterhaltung unserer Website protokollieren wir technische Fehler, die beim Aufrufen unserer Website allenfalls auftreten. Ferner werden bei der Nutzung dieser Website automatisch Informationen erhoben, die der Browser Ihres Endgeräts an unseren Host-Provider übermittelt. Dies sind:
            IP-Adresse und Betriebssystem Ihres Endgeräts,
            Browsertyp, Version, Sprache
            Datum und Uhrzeit der Serveranfrage,
            aufgerufene Datei,
            die Website, von der aus der Zugriff erfolgte (Referrer URL),
            den Status-Code (z.B. 404) und
            das verwendete Übertragungsprotokoll (z.B. HTTP/2).
            Diese Daten werden von unserem Host-Provider erhoben und gespeichert, um Prozesse und Abläufe insbesondere in Zusammenhang mit der Nutzung unserer Website und der Sicherheit und Stabilität des Computersystems optimieren zu können.
            Weitere Informationen finden Sie in der Datenschutzerklärung der ${CONSTANTS.hoster_firma} unter ${CONSTANTS.hoster_url_datenschutz}.
            Sofern die DSGVO anwendbar ist, sind Grundlage für diese Datenbearbeitung Art. 6 Abs. 1 lit. f DSGVO.
      
      2. Kontaktformular

            Wenn Sie unser Kontaktformular benutzen, werden Ihre Angaben aus dem Anfrageformular zur Bearbeitung der Anfrage und für den Fall von Anschlussfragen von uns bearbeitet. In der Regel benötigen wir folgende Angaben:
            
            1. Vor- und Nachname

            2. E-Mail-Adresse

            5. Telefonnummer

            6. Betreff

            7. Nachrichteninhalt

            Wir verwenden die von Ihnen mitgeteilten Daten, um Ihre Anfrage zu beantworten. 
            Sofern die DSGVO anwendbar ist, sind Grundlage für diese Datenbearbeitung Art. 6 Abs. 1 lit. f DSGVO.

      3. Buchungsformular

            Wenn Sie unser Buchungsformular benutzen, werden Ihre Angaben aus dem Anfrageformular zur Bearbeitung der Anfrage und für den Fall von Anschlussfragen von uns bearbeitet. In der Regel benötigen wir folgende Angaben:
            
            1. Vor- und Nachname (jeder teilnehmenden Person)

            2. E-Mail-Adresse (jeder teilnehmenden Person)

            5. Telefonnummer (jeder teilnehmenden Person)

            6. Adresse, PLZ und Ort (mindestens einer teilnehmenden Person)

            7. Bemerkungen

            8. Reisedatum und Reiseziel

            Wir verwenden die von Ihnen mitgeteilten Daten, um Ihre Anfrage zu beantworten. 
            Sofern die DSGVO anwendbar ist, sind Grundlage für diese Datenbearbeitung Art. 6 Abs. 1 lit. f DSGVO.
            
      3. Cookies / Local storage

            Wir setzen auf unserer Website keine Cookies ein. Im Admin-Bereich unserer Website wird local storage eingesetzt, um die Funktionalität der Website zu gewährleisten. 
            
            Sofern die DSGVO anwendbar ist, sind Grundlage für diese Datenbearbeitung Art. 6 Abs. 1 lit. f DSGVO.
               
  4. Externe Dienste
          
      Auf unserer Website setzen wir keine Dienste von Drittanbietern ein, die Daten speichern.
          
  5. Links
          
      Auf unserer Website finden Sie Links zu Seiten von Drittanbietern. Wir sind nicht verantwortlich für die Inhalte und Datenschutzvorkehrungen auf externen Websites, welche Sie über die Links erreichen können. Bitte informieren Sie sich über den Datenschutz direkt auf den entsprechenden Websites.
          
  6. Weitergabe von Daten an Dritte

      Wir geben Ihre Daten **nie** an Dritte weiter, es sei denn, Sie haben in die Datenweitergabe eingewilligt oder wir sind aufgrund gesetzlicher Bestimmungen und/oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt oder verpflichtet. Dabei kann es sich insbesondere um die Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung geistiger Eigentumsrechte handeln.
          
  7. Ihre Rechte

      Soweit von dem auf Sie anwendbaren Datenschutzrecht vorgesehen, können Sie unentgeltlich Auskunft über die auf Sie bezogenen gespeicherten Daten, deren Herkunft und Empfänger und den Zweck der Datenbearbeitung verlangen. Ebenso steht Ihnen unter den gesetzlichen Voraussetzungen ein Recht auf Berichtigung, Löschung, Einschränkung der oder Widerspruch gegen die Bearbeitung sowie, sofern die DSGVO zur Anwendung gelangt, auf Herausgabe dieser Daten zur Übertragung an eine andere Stelle zu.  
      Eine von Ihnen erteilte Einwilligung können Sie mit Wirkung für die Zukunft jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
      Bitte beachten Sie, dass die vorstehenden Rechte gesetzlichen Einschränkungen unterstehen und ggf. die Erbringung unserer Dienstleistungen beeinträchtigen oder verunmöglichen können.
      Sie sind berechtigt, Ihre Ansprüche gerichtlich durchzusetzen oder eine Beschwerde bei der zuständigen Datenschutzbehörde einzureichen. Für die Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte zuständig (http://www.edoeb.admin.ch).
          
  8. Aktualität und Änderung dieser Datenschutzerklärung

      Wir können diese Datenschutzerklärung jederzeit ändern oder anpassen. Die aktuelle Datenschutzerklärung kann auf ${CONSTANTS.domain} abgerufen werden.    
    `

  return (
    <div>
      <NavMagicContainer />
      <div className="p-8">
        <h1 className="text-3xl sm:text-5xl tracking-wide font-gili text-center pt-16">Datenschutzerklärung</h1>
        <ReactMarkdown className={`policy container mx-auto md:w-1/2`}>{policyText}</ReactMarkdown>

        <div className="container mx-auto md:w-1/2 mt-16 mb-8">
          Stand: 6. Januar 2024
        </div>
      </div>

    </div>
  )
}

export default PrivacyPolicy