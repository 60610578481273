import InputElement from './ui/form/InputElement'
import CaptchaElement from './ui/form/CaptchaElement'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Option } from 'react-dropdown';
import TextareaElement from './ui/form/TextareaElement';
import 'react-dropdown/style.css';
import { useCallback, useEffect, useState } from 'react';
import { sendContactForm } from './service/api.service'
import { Style } from './Globals';
import Button from './ui/buttons/Button';
import ButtonClose from './ui/buttons/ButtonClose';
import PhoneInput from './ui/form/PhoneInput';
import DropdownElement from './ui/form/DropdownElement';

const options = [
  `Offertanfrage`,
  `Miete Kleinbus`,
  `Feedback`,
  `Sonstige Anliegen`,
];

// Constants
const defaultSubject = options[0];

const defaultState = {
  subject: defaultSubject,
  success: false,
  errorMessage: "",
  isSubmitting: false,
}

// Types
type Props = {
  show: boolean,
  set: React.Dispatch<React.SetStateAction<boolean>>,
}

const ContactForm = ({ show, set }: Props) => {
  let [state, setState] = useState(defaultState)

  let { control, register, reset, handleSubmit, formState: { errors } } = useForm<Inputs>()

  // Change handlers
  const handleCloseModal = useCallback(() => {
    setState(defaultState)

    // Reset form
    reset()

    set(false)
  }, [set, reset])

  const handleMessageTypeChange = (arg: Option) => {
    setState({ ...state, subject: arg.value })
  }

  // USE EFFECT
  useEffect(() => {
    const body = document.body
    const staticModal = document.getElementById("staticModal")

    const handleBodyClick = (e: MouseEvent) => {
      if ((e.target as HTMLDivElement).id === staticModal?.id) {
        handleCloseModal()
      }
    }

    body.addEventListener('click', handleBodyClick)

    return () => {
      body.removeEventListener("click", handleBodyClick);
    }
  }, [handleCloseModal])

  type Inputs = {
    name: string,
    email: string,
    phone: string,
    message: string,
    captcha: string,
  }


  /* Event handlers */
  const onSubmit: SubmitHandler<Inputs> = (data) => {

    setState({
      ...state,
      isSubmitting: true,
      errorMessage: "",
    })

    let message = { ...data, subject: state.subject }

    sendContactForm(message).then(() => {
      setState({
        ...state,
        success: true,
        isSubmitting: false,
      })
    })
      .catch((error) => {
        setState({
          ...state,
          success: false,
          errorMessage: error.message === "Invalid captcha" ? "Bitte geben Sie die korrekte Lösung der Rechnung ein." : error.message,
          isSubmitting: false
        })
      })
  }

  return (
    show ?
      <div id="staticModal" className="flex justify-center items-center bg-black/50 
        fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto 
        md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div id="modal" className="relative w-full max-w-2xl max-h-full">
          {
            state.success ?
              <div className="relative bg-white rounded-lg shadow">
                <ButtonClose className={"absolute right-0"} onClick={() => handleCloseModal()} />
                <div className="flex flex-col flex-wrap items-center justify-between p-4 rounded-t py-8">
                  <div>
                    <h2 className={`${Style.Title} pb-8`}>Vielen Dank!</h2>
                  </div>
                  <p>Ihre Nachricht wurde erfolgreich gesendet!</p>
                  <p>Wir werden uns bald bei Ihnen melden.</p>
                </div>

              </div>
              :
              <div className="relative bg-white rounded-lg shadow">
                <ButtonClose className={"absolute right-0"} onClick={() => handleCloseModal()} />
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Kontaktformular
                  </h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="p-6 space-y-6">
                    <InputElement register={register}
                      label="Ihr Name" name="name" required={true}
                      error={errors.name} errorMsg="Bitte geben Sie einen Namen ein" />

                    <InputElement register={register}
                      label="E-Mail-Adresse" name="email" required={true}
                      error={errors.email} errorMsg="Bitte geben Sie eine E-Mail-Adresse ein"
                      type="email"
                    />

                    <PhoneInput name="phone" control={control} error={errors.phone} errorMsg='Bitte geben Sie eine gültige Telefonnummer ein!' />

                    <DropdownElement options={options} onChange={handleMessageTypeChange} defaultOption={defaultSubject} label="Betreff" />

                    <TextareaElement required={true} register={register} label="Nachricht" name="message" error={errors.message} errorMsg="Bitte geben Sie eine Nachricht ein" />

                    <div>
                      <h2 className='text-md font-bold mt-4 mb-2'>Datenschutz</h2>
                      Betreffend Verarbeitung Ihrer Daten, die bei Buchung an uns übermittelt werden, können Sie unsere <a href="/privacy" target="_blank" rel="noreferrer" className='underline'>Datenschutzerklärung</a> einsehen.
                    </div>

                    <div>
                      <h2 className='text-md font-bold mt-8 mb-2'>Captcha</h2>

                      <CaptchaElement
                        label="Um sicher zu gehen, dass Sie kein Roboter sind, geben Sie bitte die Lösung dieser Rechnung ein:"
                        name="captcha"
                        errorMsg="Bitte geben Sie die Lösung dieser Rechnung ein"
                        error={errors.captcha}
                        required={true}
                        register={register}
                      />
                    </div>

                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                      <Button type="submit" message="Absenden" loading={state.isSubmitting} />
                    </div>
                    {
                      !state.success && state.errorMessage !== "" &&
                      <div className='text-red-500 bg-red-100 border-red border-1 rounded p-2'>
                        {state.errorMessage}
                      </div>
                    }
                  </div>
                </form>
              </div>
          }
        </div>
      </div>
      : null
  )
}

export default ContactForm