// Created Oct 30, 2023 by Florian Rudin

import { Element } from 'react-scroll'

interface Props {
  name: string,
  isReverse?: boolean,
  car: {
    name: string,
    features?: string[],
    img: string[],
    technicalData?: string[],
  },
  description?: string,
}

const CarComponent = ({ isReverse = false, ...props }: Props) => {
  return (
    <div className='my-[5rem]'>
      <Element name={props.name} />

      <div className={`flex ${isReverse ? "flex-row-reverse" : "flex-row"} w-full flex-wrap`}>

        <div className='flex flex-row flex-wrap basis-full md:basis-2/3 grow-0 shadow-md min-h-[20rem]'>
          {
            props.car.img.map((img, index) => {
              return (
                <div className={`h-full ${props.car.img.length > 1 ? `basis-1/${props.car.img.length}` : `basis-full`} bg-center bg-cover`} style={{ backgroundImage: `url(${img})` }}></div>
              )
            })
          }
        </div>

        <div className="basis-full md:basis-1/3 md:min-h-[32rem] py-6 px-12">
          <h2 className="w-full text-3xl font-bold font-gili py-4">
            {props.car.name}
          </h2>
          {
            // Oct 30, 2023
            // Added description
            props.description &&
            <div className='mb-4'>
              {props.description}
            </div>
          }

          {
            props.car.features != null && props.car.features.length > 0 &&
            <div className="grid grid-cols-1 w-full">
              <p className='text-md font-bold mt-2'>Eigenschaften:</p>
              {
                props.car.features.map((feature, _index) => {
                  return <div className='text-gray-600'>{feature}</div>
                })
              }
            </div>
          }


          {
            props.car.technicalData != null && props.car.technicalData.length > 0 ? (
              <div className="grid grid-cols-1 w-full">
                {
                  props.car.technicalData.map((feature, index) => {
                    return <div className="text-gray-600">{feature}</div>
                  })
                }
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default CarComponent
