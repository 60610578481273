import { Link } from 'react-scroll'
import { useEffect, useState } from 'react'
import { ReactComponent as DoubleUpArrow } from './icons/double-up-arrow.svg'

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;


interface Props {
  to?: string
}

const ArrowUp = ({ to = "top" }: Props) => {
  const [showScrollUp, setShowScrollUp] = useState(false)

  const handleScroll = () => {
    setShowScrollUp(window.scrollY > window.innerHeight * 1.5)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })


  const ArrowComponent = () => {
    return (
      <div className='inline-flex flex-wrap flex-row md:flex-col justify-center items-center z-100'>
        <div className='rounded-full p-4 m-4 flex text-center grow-0  border-blue-500 border-2'>
          <DoubleUpArrow className='w-10 h-10 p-1 fill-blue-500 ' />
        </div>
        <span className='m-2 uppercase font-bold tracking-wider text-md grow-0 text-blue-500'>Nach oben</span>
      </div>
    )
  }

  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  return (
    <div className={`fixed 
      bottom-0 md:bottom-8 md:right-8 
      z-[100] 
      bg-gradient-to-t from-white/100 to-white/75
      w-full md:w-auto 
      flex justify-center md:justify-right
      transition-all ${showScrollUp ? "opacity-100 visible" : "opacity-0 invisible"}
      `}>

      {
        to === "top" ?
          <button onClick={scrollToTop}>
            <ArrowComponent />
          </button>
          : <Link to={to} spy={true} smooth={true} offset={50} duration={500} className='cursor-pointer'>
            <ArrowComponent />
          </Link>
      }

    </div>
  )
}

export default ArrowUp