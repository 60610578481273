import { Link, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";

import Logo from "../logo.svg"
import { useState } from 'react'
import PropTypes from 'prop-types';

import * as ApiService from '../service/api.service'

/* Form styles */
const inputStyle = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
const submitStyle = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
 
const Login = ({ setToken }) => {
  // Authentication
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  // Form Validation
  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  const navigate = useNavigate()

  const onSubmit = async ()  => {    
    // Send the username and password to the server
    try{
      const data = await ApiService.loginUser({username, password})
      if(!data) throw new Error();

      setToken(data) // Set token if login was successful
      navigate("/admin/dashboard")
      
    } catch (error) {
      setError("form", { message: "Login Failed"})
    }
  };

  return (
    <div>
      <div className="flex justify-center h-screen items-center">
        <div className="w-full max-w-xs">
          <img className="px-4 pb-8" src={Logo} alt="A.N.K. - Tours Logo" />
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" 
          onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Username
              </label>
              <input {...register("username", { required: true })}
                onChange={e => setUserName(e.target.value)}
                className={inputStyle} id="username" type="text" placeholder="Username" />
                {errors.username && <span className='text-sm text-red-500'>Bitte geben Sie einen Benutzernamen ein</span>}
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input {...register("password", { required: true })}
                onChange={e => setPassword(e.target.value)}
                className={inputStyle} id="password" type="password" placeholder="Passwort" />
              {/* errors will return when field validation fails  */}
              {errors.password && <span className='text-sm text-red-500'>Bitte geben Sie ein Passwort ein</span>}
            </div>
            <div className="flex items-center justify-between">
              <input type="submit" value="Login" className={submitStyle} />
            </div>

            
            {errors.form && <span className='text-sm text-red-500'>{errors.form.message?.toString()}</span>}
          </form>
          <p className="text-center text-gray-500 text-xs">
            <Link to='/'>Zurück zur Startseite</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}