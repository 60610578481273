import AnhaengerGross from './anhaenger_7.jpeg';
import AnhaengerThumb from './thumbs/anhaenger.png';
import CarComponent from './CarComponent'
import CarsKircheBw from './cars-kirche-bw.png';
import PostautoGross from './postauto2.jpg';
import PostautoThumb from './thumbs/postauto.png';
import Setra415 from './415.png';
import Setra415Thumb from './thumbs/setra-415.png';
import Setra516Black from './516-black.png';
import Setra516Blue from './516-blue.png';
import SetraBlackThumb from './thumbs/setra-516-black.png';
import SetraBlueThumb from './thumbs/setra-516-blue.png';

import Setra511Red from './setra-511-red.png';
import SetraRedThumb from './setra-red-thumb.png';
import Sprinter from './sprinter/sprinter.png';
import SprinterThumb from './thumbs/sprinter.png';
import ThumbComponent from './ThumbComponent';
import VeloAnhaenger from './veloanhaenger.png';
import VeloanhaengerThumb from './thumbs/veloanhaenger.png';
import NavMagicContainer from '../NavMagicContainer';

const SubTitle = ({ title }: { title: string }): any => {
  return (
    <h2 className={`bg-gradient-to-b from-gray-200 to-white font-gili text-5xl pt-16 mb-8 text-center mt-16`}>{title}</h2>
  )
}

const Cars = () => {
  return (
    <div>
      <div className='
        pb-4
        bg-gradient-to-br from-gray-900 via-gray-900 to-gray-700/80
        '>
        <NavMagicContainer />

        <h2 className='text-white text-5xl tracking-wider font-gili text-center mb-4 pt-16'>Unsere Fahrzeugflotte</h2>

        <div className='flex flex-wrap justify-center text-white mt-auto pt-4'>
          <div className='basis-full flex flex-wrap md:flex-nowrap justify-evenly items-center px-8'>
            <ThumbComponent id="SetraBlack" src={SetraBlackThumb} name={"Setra 516 HDH Top Class"}  />
            <ThumbComponent id="SetraBlue" src={SetraBlueThumb} name={"Setra 516 HDH Top Class"}  />
            <ThumbComponent id="SetraRed" src={SetraRedThumb} name={"Setra 511 HD"}  />
            <ThumbComponent id="Setra415" src={Setra415Thumb} name={"Setra 415 HDH"}  />
          </div>

          <div className='flex flex-wrap md:flex-nowrap justify-evenly items-center w-full px-8'>
            <ThumbComponent id="Sprinter" src={SprinterThumb} name={"Kleinbus"} />
            <ThumbComponent id="Postauto" src={PostautoThumb} name={"Postauto"} />
            <ThumbComponent id="Veloanhaenger" src={VeloanhaengerThumb} name={"Veloanhänger"} />
            <ThumbComponent id="Anhaenger" src={AnhaengerThumb} name={"Anhänger"} />
          </div>
        </div>

      </div>

      <div>
        <CarComponent name="SetraBlack" car={{
          name: "Setra 516 HDH Top Class",
          features: [
            "Reisecar",
            "50 Sitzplätze",
            "Bordküche",
            "2 Kühlschränke",
            "Navigationssystem",
            "TopSky Glasdach",
            "Komfortsitze Voyage-Supreme",
            "WC",
            "Video und CD-Player",
            "TV-Empfang",
            "Nespresso Kaffeemaschine",
            "230V Steckdose",
            "Fahrersichtkamera und Rückfahrkamera",
            "3 LCD Bildschirme",
            "Parkettboden",
            "Waschraum",
          ],
          technicalData: [
            "Länge: 13.30 m",
            "Partikelfilter",
            "Euro 6 Motor",
            "EBS / ABS / ASR",
          ],
          img: [Setra516Black],
        }} />

        <CarComponent isReverse={true} name="SetraBlue" car={{
          name: "Setra 516 HDH Top Class",
          features: [
            "Reisecar",
            "44 Sitzplätze (Normalbestuhlung 38 + Clubecke 6)",
            "Bordküche",
            "2 Kühlschränke",
            "Navigationssystem",
            "TopSky Glasdach",
            "Komfortsitze Voyage-Supreme",
            "WC",
            "Video und CD-Player",
            "TV-Empfang",
            "Nespresso Kaffeemaschine",
            "230V Steckdose",
            "Fahrersichtkamera und Rückfahrkamera",
            "3 LCD Bildschirme",
            "Parkettboden",
            "Waschraum",
          ],
          technicalData: [
            "Länge: 13.30 m",
            "Partikelfilter",
            "Euro 6 Motor",
            "EBS / ABS / ASR",
          ],
          img: [Setra516Blue],
        }} />

<CarComponent isReverse={false} name="SetraRed" car={{
          name: "Setra 511 HD",
          features: [
            "Reisecar",
            "36 Sitzplätze",
            "1 Kühlschrank",
            "Navigationssystem",
            "Komfortsitze Voyage-Supreme",
            "WC",
            "Video und CD-Player",
            "TV-Empfang",
            "Nespresso Kaffeemaschine",
            "230V Steckdose",
            "Fahrersichtkamera und Rückfahrkamera",
            "3 LCD Bildschirme",
            "Parkettboden",
            "Waschraum",
          ],
          technicalData: [
            "Länge: 10.50 m",
            "Partikelfilter",
            "Euro 6 Motor",
            "EBS / ABS / ASR",
          ],
          img: [Setra511Red],
        }} />



        <CarComponent name="Setra415" car={{
          name: "Setra 415 HDH",
          features: [
            "Reisecar",
            "48 Sitzplätze",
            "Bordküche",
            "2 Kühlschränke",
            "Navigationssystem",
            "TopSky Glasdach",
            "Komfortsitze Voyage-Supreme",
            "WC",
            "Video und CD-Player",
            "TV-Empfang",
            "Nespresso Kaffeemaschine",
            "230V Steckdose",
            "Fahrersichtkamera und Rückfahrkamera",
            "3 LCD Bildschirme",
            "Parkettboden",
            "Waschraum",
          ],
          technicalData: [
            "Länge: 12.30 m",
            "Partikelfilter",
            "Euro 5 Motor",
            "EBS / ABS / ASR",
          ],
          img: [Setra415],
        }} />
      </div>


      <div>
        <SubTitle title="Zusatzfahrzeuge" />

        <CarComponent isReverse={true} name="Sprinter"
          description={`
          Der Kleinbus kann gemietet und selbst gefahren 
          werden, sofern der/die Fahrer/-in im Besitz eines 
          Führerausweises Kat. B ist.
          Für Neulenker darf der Bus nur mit 9 Sitzplätzen 
          gefahren werden
        `}
          car={{
            name: "Kleinbus",
            features: [
              "Mietpreis: CHF 290.- pro Tag (max. 400km)",
              "Mehr Kilometer CHF 0.80/km",
              "9 bis 13 Sitzplätze inkl. Chauffeur",
              "Licht-Regensensor",
              "Klimaanlage",
              "Radio mit CD-Player",
              "Anhängerkupplung",
              "Sitzplätze mit Teillederkombination",
              "Hochdach",
              "Parktronik-System",
              "Tempomat",
            ],
            technicalData: [
              "Leistung: 163 PS"
            ],
            img: [
              Sprinter
            ]
          }} />

        <CarComponent
          name="Postauto"
          description={`
            Von Montag bis Freitag sind wir für Postauto 
            auf der Linie 99 von Giebenach nach Magden 
            über Olsberg unterwegs
          `}
          car={{
            name: "Postauto",
            img: [PostautoGross],
          }} />

      </div>


      <div>


        <SubTitle title="Anhänger" />

        <CarComponent isReverse={true} name="Anhaenger" car={{
          name: "Anhänger",
          features: [
          ],
          technicalData: [
          ],
          img: [AnhaengerGross],
        }} />

        <CarComponent name="Veloanhaenger" car={{
          name: "Veloanhänger",
          features: [
            "Wir transportieren bis zu 34 Fahrräder oder 6 Motorräder sicher und zuverlässig ans Reiseziel."
          ],
          technicalData: [
          ],
          img: [VeloAnhaenger],
        }} />

      </div>

      <div className="h-24"></div>

      {
        // Church pic
      }
      <div className='-mb-1 h-[35rem] w-full bg-bottom bg-no-repeat bg-cover' style={{ backgroundImage: `url(${CarsKircheBw})` }}></div>
    </div>
  )
}

export default Cars