import { CSSProperties } from 'react'
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg'

import { useState, useEffect } from 'react'

enum Colors {
  Pink = "border-pink-500 hover:bg-pink-500 text-pink-500",
  Blue = "border-blue-700 hover:bg-blue-700 text-blue-700",
  Green = "border-green-500 hover:bg-green-500 text-green-500",
}
const defaultColor: Colors = Colors.Blue


interface Props {
  message: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  style?: CSSProperties,
  color?: string,
}

const ButtonContinue = (props : Props) => {
  let [color, setColor] = useState<Colors>(defaultColor)

  useEffect(() => {
    switch(props.color){
      case "blue":
        setColor(Colors.Blue)
        break;      
      case "green":
        setColor(Colors.Green)
        break;
      default:
        setColor(defaultColor)
    }
  }, [props.color])

  return (
    <button 
      className={`flex items-center mt-4 px-4 py-2 
      rounded-full border-2 
      text-xl hover:text-white ${color}`}
      onClick={props.onClick}
      style={props.style}
      >
      {props.message}
      <ArrowRight className='w-6 inline-block ml-2' />
    </button>  
    )
}

export default ButtonContinue
