import { Editor, EditorState, RichUtils } from 'draft-js'

interface Props {
  editorState: EditorState
  onEditorStateChange: (editorState: EditorState) => void
}



const AnkEditor = ({ editorState, onEditorStateChange }: Props) => {

  const onBoldClick = (e: any) => {
    e.preventDefault()
    const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD')
    onEditorStateChange(newState)
  }

  const handleKeyCommand = (command: any, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onEditorStateChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const toggleBulletPoints = (e: any) => {
    e.preventDefault()
    onEditorStateChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
  }

  return (
    <div style={{
      maxWidth: '100%'
    }}>
      <div className="flex gap-4">
        <button onClick={onBoldClick} className="flex items-center justify-center px-2 bg-black text-white font-bold">Text betonen</button>
        <button onClick={toggleBulletPoints} className="h-8 bg-black text-white px-2">Auflistung erstellen</button>
      </div>
      <div className="border border-slate-600 min-h-6 p-4" style={{overflowY: "auto"}}>
        <Editor editorState={editorState} onChange={onEditorStateChange} handleKeyCommand={handleKeyCommand}  customStyleMap={{
        }}/>
      </div>
    </div>
  )
}

export default AnkEditor