import { FieldError, RegisterOptions, UseFormRegister } from 'react-hook-form'

const inputStyle = `
  border border-[#0000003b]
  bg-transparent mt-2 text-[1rem] appearance-none rounded w-full 
  py-4 px-3 text-gray-700 leading-tight 
   shadow`;
const errorStyle = `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`;

interface Props {
  label: string
  name: string,
  required?: boolean,
  error?: FieldError,
  errorMsg: string,
  register: UseFormRegister<any>,
  className?: string,
  type?: React.HTMLInputTypeAttribute,
  placeholder?: string,
  registerOptions?: RegisterOptions,
}

const FormElement = ({ className, register, label, name, required = false, error, errorMsg, ...props }: Props) => {
  return (
    <div className={className}>
      <label htmlFor={name} className='mt-4'>{label}</label>
      <input {...register(name, { ...props.registerOptions, required: required || props.registerOptions?.required })} 
        className={`${inputStyle}`} type={props.type ?? "text"} placeholder={props.placeholder} />
        { error && <span className={errorStyle}>{error.message || errorMsg }</span> }
    </div>)
}

export default FormElement