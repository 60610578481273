import { ReactComponent as PlusIcon } from '../icons/plus-icon.svg'

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  className?: string,
}
const ButtonClose = (props : Props) => {
  return (
    <button className={`${props.className} h-8 w-8 m-2 aspect-square text-gray-400 hover:text-gray-500 cursor-pointer`}
      onClick={props.onClick}
      >

      <PlusIcon className='rotate-45' />
    </button>
    )
}

export default ButtonClose