import { Style } from "../Style"
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';

import { Gallery } from "../../service/dto.types"

import * as ApiService from "../../service/api.service"
import * as GalleryService from "../../service/gallery.service"
import { getDateRange, parseTrip } from "../../service/trip.service"

import { useSession as Session } from "./Container";

const List = () => {

  const { token, logout } = Session()

  /* Token authentication */
  const [galleries, setGalleries] = useState<Gallery[]>()
  const [galleriesLoaded, setGaleriesLoaded] = useState(false)

  const loadGaleries = async () => {
    ApiService.fetchGalleries().then((fetchedGalleries) => {
      // Set state
      const galleryPromises = fetchedGalleries.map(gallery =>  {
        if(gallery.trip_id){
          try{
            return ApiService.fetchTripById(gallery.trip_id.toString()).then((trip) => {
              return GalleryService.parseGallery({...gallery, trip: parseTrip(trip)})
            })
          }
          catch (error)  {
            toast.error("Fehler beim Laden der zugehörigen Reisen")
          }
        }

        return GalleryService.parseGallery(gallery)
      })

      Promise.all(galleryPromises).then((g) => {
        setGalleries(g)
        setGaleriesLoaded(true)
      })
    })
    .catch(() => {
      toast.error("Fehler beim Laden der Galerien")
    })
  }

  useEffect(() => {
    // Fetch galleries
    setGaleriesLoaded(false)
    loadGaleries().then(() => {
      setGaleriesLoaded(true)
    })
  }, [])

  // Delete gallery
  const deleteGallery = async (galleryId: number) => {
    ApiService.checkToken(token).then(() => {
      //Delete gallery
      ApiService.deleteGallery(galleryId.toString(), token).then(() => {        
        setGalleries(galleries?.filter(gallery => gallery.id !== galleryId))
        toast.success("Löschen erfolgreich!")
      })
      .catch(() => {
        toast.error("Löschen fehlgeschlagen!")
      })
    })
    .catch(() => {
      logout()
      toast.error("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.") 
    })
  }

  // handle delete Gallery
  const handleDeleteGallery = async (galleryId: number) => {
    confirmAlert({
      title: 'Sind Sie sicher, dass Sie diese Reise löschen möchten?',
      message: 'Sie können diese Aktion nicht rückgängig machen.',
      buttons: [
        { label: 'Ja', onClick: () => deleteGallery(galleryId) },
        { label: 'Abbrechen', onClick: () => {} }
      ]
    });
  }

  const getMainImageThumb : (gallery: Gallery)=>string = (gallery) => {
    if(!gallery || !gallery.images || gallery.images.length < 1) { return " " }
    
    const image = gallery.images.filter(i => i.is_main).length > 0 ? 
      gallery.images.filter(i => i.is_main)[0] : 
      gallery.images[0]

    if(!image.thumb) return ""
    else{
      return image.thumb
    }
  }

  return (
    <div>
      <h2 className={Style.Title2}>Galerien</h2>
      {
        !galleriesLoaded ? <div className="ui active centered inline loader"></div> :
        // List of galleries
        galleries && galleries.length ? galleries.sort(GalleryService.dateCompare("desc")).map((gallery) => {
          return (
            <div className='flex items-center bg-slate-200 p-4 mb-4' key={gallery.id}>
              <div className="mr-4 w-48 overflow-hidden">
                <img className="ui image w-full object-cover object-center h-32" src={getMainImageThumb(gallery)} alt={gallery.title} />
              </div>
              <div>
                <h3 className='font-bold text-xl'>{gallery.title}</h3>
                {
                  gallery.trip &&
                    <div>
                      <p>Gehört zur Reise: {gallery.trip.title}</p>
                      <p>
                        {getDateRange(gallery.trip)}
                      </p>
                    </div>
                }                
              </div>
              <div className="ml-auto">
                <Link to={`/admin/galleries/${gallery.id}/edit`} state={{ data: gallery, edit: true }} 
                className='bg-slate-800 text-white text-sm font-bold py-2 px-4 m-2'>Bearbeiten</Link>
                {
                  <input 
                    type="button" 
                    value="Löschen" 
                    className='bg-red-800 text-white text-sm font-bold py-2 px-4 m-2' 
                    onClick={() => { gallery.id && handleDeleteGallery(gallery.id) }} />
                }
              </div>
            </div>
          )
        }) : <div>Keine Galerien vorhanden!</div>
      }

    </div>
  )
}

export default List