import { Link } from "react-router-dom"
import Logo from './logo.svg'
import { useState } from 'react'
import { Style } from './Globals'

interface Props {
  showMenu: boolean,
}

const Headline = (props: Props) => {
  const [menuIsOpen, toggleMenu] = useState(false);

  const navItemClassName = `flex items-center justify-center 
    py-2 px-8 whitespace-nowrap 
    ${Style.BorderColor} border-t-4 
    hover:border-green-500 hover:text-gray-200
    text-white
    tracking-wide 
    text-2xl font-gili`;

  const NavLinkStyle = `block text-4xl ${Style.TextLight} font-bold p-8 border-b`

  return (
    <div className={`w-full bg-gradient-to-b ${Style.from} ${Style.to} 
      fixed drop-shadow-md
      transform ${props.showMenu ? "translate-none" : "-translate-y-full"}
      transition duration-200 ease-in-out z-20
      `} id="nav">

      {
        // Mobile Nav
        <div className={`
          transform ${menuIsOpen ? "translate-none" : "-translate-x-full"} 
          transition duration-200 ease-in-out
          absolute h-screen w-5/6 ${Style.BgLight} 
          z-20 shadow-lg`}>
          <Link onClick={() => toggleMenu(false)} to="/" className={NavLinkStyle}>Reisen</Link>
          <Link onClick={() => toggleMenu(false)} to="/about" className={NavLinkStyle}>Über uns</Link>
          <Link onClick={() => toggleMenu(false)} to="/cars" className={NavLinkStyle}>Cars</Link>
          <Link onClick={() => toggleMenu(false)} to="/galleries" className={NavLinkStyle}>Fotogalerien</Link>
        </div>
      }

      {
        // Nav
        <div className="container m-auto flex">
          <div className="max-w-[390px] py-4 px-6">
            <Link reloadDocument to="/" state={{ showTrips: false }}>
              <img src={Logo} alt="A.N.K. Tours Logo" className="mt-2 min-w-[200px]" />
            </Link>
          </div>
          <div className="pl-4 flex ml-auto ">
            <Link to="/" className={navItemClassName + " hidden lg:flex"} state={{ showTrips: true }}>Reisen</Link>
            <Link to="/about" className={navItemClassName + " hidden lg:flex"}>Über uns</Link>
            <Link to="/cars" className={navItemClassName + " hidden lg:flex"}>Cars</Link>
            <Link to="/galleries" className={navItemClassName + " hidden lg:flex"}>Fotogalerien</Link>

            {/** Toggle */}
            <button className={`lg:hidden flex grow py-4 px-8 items-center justify-center`} onClick={() => toggleMenu((prev) => !prev)}>
              <div className={`space-y-2 relative w-8 h-8 ${menuIsOpen ? "mt-2 -mb-2" : ""}`}>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out ${menuIsOpen ? "absolute rotate-45 top-2" : ""}`}></div>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out ${menuIsOpen ? "absolute -rotate-45" : ""}`}></div>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out  ${menuIsOpen ? "absolute -rotate-45" : ""}`}></div>
              </div>
            </button>
          </div>
        </div>
      }
    </div>
  )
}

export default Headline