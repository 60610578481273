import { Style } from './Globals'
import AgbPdf from './agb.pdf'
import { ReactComponent as PassportIcon } from './ui/icons/passport-svgrepo-com.svg'
import { ReactComponent as SignUpIcon } from './ui/icons/sign-up.svg'
import { ReactComponent as SeatIcon } from './ui/icons/seat-3.svg'
import { ReactComponent as PlanChangeIcon } from './ui/icons/route-path-way.svg'
import { ReactComponent as InsuranceIcon } from './ui/icons/insurance-27.svg'
import { ReactComponent as LuggageIcon } from './ui/icons/luggage-7.svg'
import Button from './ui/buttons/Button'
import NavMagicContainer from './NavMagicContainer'

const h2Class = `${Style.SubTitle} pb-2 font-gili pt-2`
const textBlockClass = `md:w-1/2 mx-auto mt-4 flex justify-center md:text-center`
const iconClass = `w-24 h-24 grow-0 shrink-0 mx-auto p-2`

type AgbElementProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  customClass: string,
  text: string,
  title: string,
}
const AgbElement = ({ title, text, Icon, customClass }: AgbElementProps) => {
  return (
    <div className='my-12'>
      <Icon className={`${iconClass} ${customClass}`} />
      <h2 className={h2Class}>{title}</h2>
      <div className={`${textBlockClass}`}>
        {text}
      </div>
    </div>
  )
}

const Agb = () => {

  return (
    <div>
      <NavMagicContainer />
      <div className="p-4 container mx-auto">

      <h1 className={`pt-16 ${Style.Title} text-center`}>Allgemeine Geschäftsbedingungen</h1>

      <AgbElement
        title='ID / Pass'
        text="Sie brauchen eine gültige Identitätskarte oder einen Pass, um eine Reise antreten zu können."
        Icon={PassportIcon}
        customClass='fill-gray-200' />

      <AgbElement
        title='Anmeldung'
        text="Wir bitten um möglichst frühzeitige und schriftliche Anmeldung. Ihre Anmeldung wird von uns bestätigt. Mit der Anmeldung werden die Reisebedingungen anerkannt.        "
        Icon={SignUpIcon}
        customClass='fill-gray-200' />

      <AgbElement
        title='Sitzplätze'
        text="Die Plätze im Autocar werden in der Reihenfolge der Anmeldung vergeben."
        Icon={SeatIcon}
        customClass='fill-gray-200' />

      <AgbElement
        title='Programm-Änderungen'
        text="Wir behalten uns kleine Programm-Änderungen vor."
        Icon={PlanChangeIcon}
        customClass='fill-gray-200' />

      <AgbElement
        title='Haftung und Versicherung'
        text="Durch unsere Haftpflichtversicherung sind Sie während der Fahrt im Autocar, im Sinne der schweizerischen Gesetzgebung, vollumfänglich versichert. Ausserhalb des Autocars besteht unsererseits keine Haftung. Auf Wunsch besorgen wir für Sie eine Unfall- und Reisegepäckversicherung. Wir beraten Sie gerne.
        Annullation
        Bei Rücktritt von einer bereits bestätigten Buchung sind wir gezwungen, eine Bearbeitungsgebühr von Fr. 50.- zu verrechnen. Ferner müssen wir bei einem Annullierungszeitpunkt von weniger als 31 Tagen vor der Abreise folgende Kosten in Rechnung stellen:
        40% vom Pauschalpreis 30 – 15 Tage vor der Abreise 50% vom Pauschalpreis 14 – 8 Tage vor der Abreise 70% vom Pauschalpreis 7 – 4 Tage vor der Abreise 100% vom Pauschalpreis 3 – 0 Tage vor der Abreise
        Auf Wunsch besorgen wir Ihnen eine Annullationskosten-Versicherung. Wir beraten Sie gerne."
        Icon={InsuranceIcon}
        customClass='fill-gray-300' />

      <AgbElement
        title='Reisegepäck'
        text="Die Gepäckstücke sind mit den Etiketten, welche Sie mit den Reiseunterlagen zugestellt erhalten, zu adressieren.
        Wir bitten Sie, das Verladen Ihres Gepäcks zu überwachen, da wir für eventuelle Beschädigungen und Verluste keine Haftung übernehmen können (Reisegepäck- Versicherung)."
        Icon={LuggageIcon}
        customClass='fill-gray-300' />


      <div className='flex justify-center mt-24'>
        <Button message='AGB als Pdf herunterladen' onClick={() => window.location.href = AgbPdf} />
      </div>

      <div className='h-24'></div>
    </div>
    </div>

  )
}

export default Agb