import { Controller } from "react-hook-form";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { deDE } from '@mui/x-date-pickers/locales';

const inputStyle = `appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow`;
const errorStyle = `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`;

type Props = {
  name: string,
  selectedDate?: Date | null | undefined,
  onChange?: (date: Date) => void,
  control: any,
  label: string,
  required?: boolean,
  error: any,
  errorMsg: string,
  defaultValue?: string,
  placeholder?: string,
}

const DatePickerElement = (props: Props) => {
  return (
    <div className="flex flex-cols flex-wrap">
      <label htmlFor={props.name} className="mb-2">{props.label}</label>
      <Controller
        control={props.control}
        rules={{
          required: props.required ?? false,
          
          validate: {
            date: (value: string) => {
              return dayjs(value).isValid();
            }
          }
        }}
        name={props.name}
        render={({ field: { onChange, value } }) =>
          /* Birthday */
          <LocalizationProvider 
            dateAdapter={AdapterDayjs} 
            adapterLocale="de"
            localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
            >
            <DatePicker
              label={props.placeholder}
              className={`${inputStyle}`}
              onChange={onChange}
              defaultValue={props.defaultValue && props.defaultValue !== "" ? dayjs(props.defaultValue) : undefined}
              slotProps={{
                textField: {
                  required: true,
                  name: 'birthday',
                },
              }}
            />
          </LocalizationProvider>
        }
      />
      {props.error && <span className={errorStyle}>{props.errorMsg}</span>}

    </div>
  )
}

export default DatePickerElement