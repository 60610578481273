import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useToken() {
  const getToken = () => {

    const storedToken = localStorage.getItem('token');
    
    if(!storedToken) {
      return null;
    }

    const userToken = JSON.parse(storedToken);
    if (userToken.expiredDate && userToken.expiredDate < Date.now()) {
      localStorage.removeItem('token');
      return null;
    }

    return userToken?.token
  };

  /* Initialize state */
  const [token, setToken] = useState(getToken());
  const navigate = useNavigate();

  const saveToken = (userToken: any) => {
    // Check if token is null
    if(!userToken) return null;

    // Save token to localStorage
    localStorage.setItem('token', JSON.stringify(userToken));

    // Update state
    setToken(userToken.token);
  };

  const logout = (redirect=true, redirectPath="/login") => {
    localStorage.removeItem('token');
    setToken(null);

    // Redirect if path is provided
    redirect && navigate(redirectPath)
  }

  return {
    setToken: saveToken,
    logout: logout,
    token
  }
}
