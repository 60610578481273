/* Import Team pictures */
import { Style } from './Globals'
import NavMagicContainer from './NavMagicContainer'


interface IJob {
  title: string,
  workload: string,
  profile: string,
  offer: string,
}


const Footer = () => 
  <address className='mt-6'>
      A.N.K. Tours AG<br />
      Hanspeter Niklaus<br />
      Gerberstrasse 9<br />
      4410 Liestal<br />
      061 951 24 24<br />
      <a href="mailto:info@ank-tours.ch" className='underline hover:no-underline'>info@ank-tours.ch</a>
  </address>


const jobs: IJob[] = [
  {
    title: "CAR-CHAUFFEUR/-CHAUFFEUSE",
    workload: "20-50%",
    profile: `Du besitzt den Führerausweis Kategorie D und bist zwischen 21
      und 65 Jahren alt und schätzt den persönlichen Kontakt mit den Fahrgästen.
      Du bist zuverlässig, verantwortungsbewusst und behältst auch in
      hektischen Situationen einen kühlen Kopf.
      Dank Deiner Flexibilität kommst Du auch mit unregelmässigen Arbeitszeiten zurecht.`,
    offer: `Eine selbstständige und abwechslungsreiche Arbeit in einem tollen,
      familiären Arbeitsumfeld. Du wirst auf Carfahrten (Tagesfahrten,
      Ferienreisen, Transfers) und auf unserer Postauto-Linie Magden-Giebenach eingesetzt.
      Unsere drei Setra-Reisebusse gehören zu den modernsten Modellen und bieten ein tolles Fahrgefühl.`,
  },
]

const Jobs = () => {

  return (
    <div>
      <NavMagicContainer />
      <div className="container m-auto text-center p-4">
      <h1 className={`${Style.Title} pt-16`}>Jobs</h1>
      <strong className={`p-4 ${Style.TextColor} text-2xl`}>Wir freuen uns auf dich!</strong>
      {
        jobs.map((job) => {
          return (
            <div className='bg-slate-100 my-8 p-8 md:w-2/3 m-auto'>
              <h2 className='text-3xl mb-4'>{job.title} ({job.workload})</h2>

              <div className="flex flex-wrap flex-cols text-left justify-center">
                <div className='p-4'>
                  <h3 className='font-bold'>Dein Profil</h3>
                  {job.profile}
                </div>
                <div className='p-4'>
                  <h3 className='font-bold'>Unser Angebot</h3>
                  {job.offer}
                </div>
              </div>

              <div className='mx-auto my-4 text-sm'>
              Hast Du Interesse an dieser Tätigkeit und erfüllst unser Profil, dann melde Dich bei uns.
              <Footer />
              </div>
            </div>
          )
        })
      }
    </div>
    </div>

  )
}

export default Jobs