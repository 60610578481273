import { IAction } from "./service/app.types"
import { formatPrice, getDateRange, isVoranzeige as getIsVoranzeige, isAusgebucht, isAbgesagt } from "./service/trip.service"
import { Trip, Price } from './service/dto.types'
import { useState, useEffect, useReducer } from 'react'
import { useParams } from "react-router-dom";
import BookingForm from './BookingForm'
import * as ApiService from './service/api.service'
import { parseTrip, getTripDaysString } from './service/trip.service'
import draftToHtml from 'draftjs-to-html';
import { Style } from './Globals'
import Loading from './Loading'

import { convertFromRaw } from 'draft-js';

import { PhotoAlbum, Photo } from "react-photo-album";
import "./Gallery.css"
import Lightbox, { SlideImage } from "yet-another-react-lightbox";

import TeamImBus from "./images/team_im_bus.png"
import ButtonContinue from "./ui/buttons/ButtonContinue";

interface IState {
  trip: Trip,
  tripLoaded: boolean,
  hasDetail: boolean,
  hasServices: boolean,
  price: Price
}

const initialState: IState = {
  trip: {} as Trip,
  tripLoaded: false,
  hasDetail: false,
  hasServices: false,
  price: {
    category: "Standard",
    price: 0,
  },
}

let SET_TRIP = 'SET_TRIP'
let SET_TRIP_LOADED = 'SET_TRIP_LOADED'

const getNavHeight = () =>{
  let height = document.getElementById("nav")?.offsetHeight;
  
  if(height !== undefined && height != null){
    return(height)
  }
  return 0
}

const TripDetail = () => {  
  let [navHeight, setNavHeight] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setNavHeight(getNavHeight()-1)
    }, 200)
  }, [])

  const tripReducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case SET_TRIP:
        let trip = action.payload as Trip
      
        let hasDetail = false;
        let hasServices = false;
        let price = {} as Price;

        try{
          hasDetail = trip.detail !== undefined && convertFromRaw(JSON.parse(trip.detail)).hasText()
        }
        catch(err){
          hasDetail = false
        }

        try{
          hasServices = trip.services !== undefined && convertFromRaw(JSON.parse(trip.services)).hasText()
        }
        catch(err){
          hasServices = false
        }

        try{
          price = (action.payload as Trip).prices?.find(p => p.category === "Standard") ?? initialState.price
        }
        catch(err){
          console.log(err)
        }

        return { ...state, 
          trip: action.payload,
          hasDetail: hasDetail,
          hasServices: hasServices,
          tripLoaded: true,
          price: price,
        }

      case SET_TRIP_LOADED:
        return { ...state, tripLoaded: action.payload } 
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(tripReducer, initialState)
  const [bookingFormOpen, setBookingFormOpen] = useState(false)
  const { tripId } = useParams();
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    dispatch({ type: SET_TRIP_LOADED, payload: false })

    if(tripId){
      ApiService.fetchTripById(tripId).then((trip) => {
        try{
          dispatch({ type: SET_TRIP, payload: parseTrip(trip) })
        }
        catch(e){
          console.log(e)
        }
      })
    }
  }, [tripId])

  const TripDetailElement = () => {
    if (state.trip !== undefined && state.trip.detail !== undefined) {
      try {
        return <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(state.trip.detail)) }} />
      }
      catch (e) {
        console.log(e)
      }
    }

    return null
  }  

  const TripServicesElement = () => {
    if (state.trip !== undefined && state.trip.services !== undefined) {
      try{
        return <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(state.trip.services)) }} />
      }
      catch (e) {
        console.log(e)
      }
    }
    
    return null
  }

  const backgroundImage = () => {
    if (state.trip !== undefined && state.trip.images !== undefined && typeof state.trip.images[Symbol.iterator] === 'function'
      && state.trip.images.length > 0) {
      return state.trip.images.filter((image) => image.is_main).length > 0 ?
        state.trip.images.filter((image) => image.is_main)[0].src :
        state.trip.images[0].src
    }
    return ""
  }

  const slides = state.trip.images ? state.trip.images.map(img => {
    return {
      src: img.src,
      width: 1,
      height: 1,
    } as Photo
  }) : [] as Photo[]

  const slides2 = state.trip.images ? state.trip.images.map(img => {
    return {
      src: img.src,
    } as Photo
  }) : [] as SlideImage[]

  const isVoranzeige = getIsVoranzeige(state.trip)

  return (
    <div>
      <div className="bg-green-400 transition-all ease-in-out " style={{ height: `${navHeight}px`}}></div>

      {
        state.tripLoaded ?
        <div>
            <div className='flex justify-center items-center text-4xl p-8 min-h-[25rem] bg-cover bg-center bg-gray-300'
              style={backgroundImage && { backgroundImage: `url("${backgroundImage()}")` }}>

              <div className='relative lg:w-1/2 py-8 text-center'>
                <div className='absolute bg-slate-100/75 blur-xl top-0 bottom-0 left-0 right-0 rounded-full z-0'></div>
                <div className={`${Style.SubTitle2} inline-block mt-8 blur-0 font-bold `}>
                  {isVoranzeige ? `Voranzeige: ${state.trip.date_begin?.getFullYear()}` : getDateRange(state.trip)}
                </div>
                <h1 className={`${Style.TitleHuge} z-10 blur-0 pb-4 mt-2`}>{state.trip.title}</h1>

            </div>
          </div>

          <div className="container m-auto p-4">
            <div className='flex flex-wrap'>
              <div className="flex basis-full mt-8 text-center justify-center">
                <div className='md:w-1/2 text-lg'>
                  {state.trip.description}
                </div>
              </div>
              {
                !isVoranzeige &&
                <>
                    {/** Price &Book */}
                  <div className="flex basis-full mt-8 justify-center">
                    <div className='md:w-1/2 text-center'>
                      {
                        // Price
                        <p key={index} className={`font-bold text-3xl`}>
                          {formatPrice({price: state.price.price})}
                        </p>
                      }

                      {
                        // Zuschläge
                        state.trip.prices?.filter(p => p.category !== "Standard").map((price, index) => {
                          return (
                            <p key={index} className="mt-2 text-gray-700">
                              {price.category}: {formatPrice({price: price.price})}
                            </p>
                          )
                        })
                      }
                      <br />
                      <span className={`${Style.SubTitle2}`}>
                        für {getTripDaysString(state.trip)}
                      </span>
                    </div>
                  </div>
                </>
              }             
            </div>
          </div>

          <div className="container m-auto p-4 mb-4 text-center">
          {
            // Booking Form

            !isVoranzeige && !isAusgebucht(state.trip) && !isAbgesagt(state.trip) &&
            <div>
              {
                !bookingFormOpen ? 
                  <ButtonContinue message="Jetzt buchen" style={{ marginLeft: "auto", marginRight: "auto"}} onClick={() => setBookingFormOpen(!bookingFormOpen)} />
                  : !!state.trip.id && <BookingForm tripId={state.trip.id} onClose={() => setBookingFormOpen(false)} />
              }     
            </div>
          }
          </div>
          {
            state.trip.images && state.trip.images.length > 1 &&

            <div className={`w-full mx-auto p-4 text-center ${Style.BgDark}`}>
              <PhotoAlbum
                layout="rows"
                photos={slides}
                targetRowHeight={150}
                renderRowContainer = {({ rowContainerProps, rowIndex, rowsCount, children }) => (
                  <>
                      <div {...rowContainerProps} style={{ ...rowContainerProps.style, justifyContent: "center"}}>{children}</div>
                  </>
                )}

                renderPhoto={({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
                  <div className="h-56 aspect-square overflow-hidden m-4">
                    <img alt={alt} style={{ ...style, objectFit: "cover", height: "100%", width: "auto", padding: 0 }} {...restImageProps} />
                  </div>
                )}
                onClick={({ index }) => {
                  setIndex(index)
                }}
              />
              <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides2}
              />

            </div>
          }

            {
              state.hasDetail &&
              <div className={`p-8 ${Style.BgDark} w-full text-white min-h-[200px]`}>
                <h2 className='text-5xl m-8 tracking-wide text-blue-500 lg:absolute'>Reisedetails</h2>
                <div className='container m-auto flex justify-center'>
                  <div className="lg:basis-1/2">
                    <div className="p-4 text-lg text-slate-100 detail">
                      <TripDetailElement />
                    </div>
                  </div>
                </div>
              </div>
            }

          {
            state.hasServices &&
            <div className="text-stone-300">
              <div className="flex justify-left w-full min-h-[50rem] bg-cover bg-center" style={{ backgroundImage: `url(${TeamImBus})` }}>
                <div className="flex flex-cols flex-wrap justify-center w-full md:w-1/2 
                  bg-gradient-to-r from-white/75 to-white/25  p-4 services text-black">
                  <div className="w-5/6 md:w-1/2">
                    <h2 className={`${Style.Title} pt-8 text-5xl basis-full -ml-6 mt-8`}>Unsere Leistungen</h2>

                    <div className='pt-8 text-xl'>
                      <TripServicesElement />
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          }
        </div> 
        : <Loading message="Reise wird geladen" />
      }
    </div>
  )
}

export default TripDetail