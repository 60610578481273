import { Trip as ITrip } from './service/dto.types'
import { getDateRange, formatPrice, isVoranzeige, getDaysLength, isAusgebucht as tripIsAusgebucht, isAbgesagt as tripIsAbgesagt } from './service/trip.service'
import { Style } from './Globals'

import { useEffect, useState } from 'react'

const TripAvatar = ({ trip }: { trip: ITrip }) => {
  const [isAbgesagt, setIsAbgesagt] = useState(false)
  const [isAusgebucht, setIsAusgebucht] = useState(false)

  useEffect(() => {
    setIsAbgesagt(tripIsAbgesagt(trip))
    setIsAusgebucht(tripIsAusgebucht(trip))
  }, [trip])

  const Ausgebucht = () => {
    if (!isAbgesagt && isAusgebucht) {
      return (
        <div className='absolute w-full h-full bg-gray-600/75 flex justify-center items-center z-10'>
          <div className='bg-green-400 text-white py-2 px-4 text-3xl font-gili transform -rotate-[15deg]'>
            Ausgebucht
          </div>
        </div>
      )
    }

    return null
  }

  const Abgesagt = () => {
    if (isAbgesagt) {
      return (
        <div className='absolute w-full h-full bg-gray-600/75 flex justify-center items-center z-10'>
          <div className='bg-gray-800 text-white py-2 px-4 text-3xl font-gili transform -rotate-[15deg]'>
            Abgesagt
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <div className={`hover:scale-105 hover:shadow-lg
        transition transform duration-500 
        relative 
        ${Style.Picture}
        bg-cover
        aspect-[4/3] 
        flex
        `}
        style={{ backgroundImage: trip.images && trip.images.length > 0 ? `url("${(trip.images.find(i => i.is_main) ?? trip.images.at(0))?.thumb}")` : "" }}
      >

        <Abgesagt />
        <Ausgebucht />

        {
            // Top line
            <div className='absolute w-full top-0'>
              <h2 className={`font-gili text-white text-2xl
              bg-gradient-to-tr from-black/50 line-clamp-3  p-2`}>
                {trip.title}
              </h2>
            </div>
        }

        <div className='mt-auto relative w-full'>
          {
            !isVoranzeige(trip) &&
            (
              // Bottom line
              <div className="flex items-stretch justify-between bg-gradient-to-bl from-white to-white/75 ">
                {

                  // Price
                  <div className='bg-gradient-to-br from-blue-500 to-blue-700 
                  font-gili text-white p-2 shrink'>
                    <p className={`text-xl mr-2`}>
                      {
                        trip.prices && trip.prices.length > 1 ? "ab " : ""
                      }

                      {
                        trip.price !== undefined && 
                        <span style={{ whiteSpace: 'nowrap'}}>
                          { formatPrice({ price: trip.price }) }
                        </span>
                      }
                    </p>
                    {
                      /*
                      <span className='text-gray-200 whitespace-nowrap'>
                      für {getTripDaysString(trip)}
                      </span>
                      */
                    }
                  </div>

                }

                {
                  // Date range
                  <div className={`grow text-md tracking-wide flex justify-end`}>
                    <span className='px-2 my-3 mr-2 whitespace-nowrap'>
                      {getDaysLength(trip) > 0 && !isVoranzeige(trip) && getDateRange(trip)}
                    </span>
                  </div>
                }

              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default TripAvatar