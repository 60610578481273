import './fonts/gili.ttf';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import About from './components/about/About';
import Admin from './components/admin/App'
import AdminRoutes from './components/admin/Routes'
import Agb from './components/Agb';
import App from './App';
import Cars from './components/cars/Cars';
import ErrorPage from "./components/ErrorPage";
import Galleries2 from './components/Galleries_2';
import Gallery from './components/Gallery';
import Jobs from './components/Jobs';
import Page from './components/Page';
import PastTrips from './components/PastTrips';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import TripDetail from './components/TripDetail';

import PrivacyPolicy from './components/Privacy/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    element: <Page />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/trips/past",
        element: <PastTrips />,
      },
      {
        path: "/trips/:tripId",
        element: <TripDetail />,
      },
      {
        path: "/about",
        element: <About />,
      },

      {
        path: "/jobs",
        element: <Jobs />,
      },
      {
        path: "/agb",
        element: <Agb />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/cars",
        element: <Cars />,
      },
      {
        path: "/galleries",
        element: <Galleries2 />,
      },
      {
        path: "/galleries/:galleryId",
        element: <Gallery />,
      },
    ]
  },
  {
    element: <Admin />,
    errorElement: <ErrorPage />,
    path: "/admin",
    children: AdminRoutes,
  },
  {
    path: "/login",
    element: <Admin /> ,
  },
  {
    path: "/error",
    errorElement: <ErrorPage />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
