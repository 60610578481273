import { Gallery } from './dto.types'

// Initialize a new trip
export const getNewGallery = (): Gallery => {
    return {
        id: undefined,
        title: "",
        description: "",
        date_begin: undefined,
        date_end: undefined,
        images: [],
    }
}

export const parseGallery = (gallery: any): Gallery => {
    return {
        ...gallery,
        date_begin: gallery.date_begin ? new Date(gallery.date_begin) : undefined,
        date_end: gallery.date_end ? new Date(gallery.date_end) : undefined,
    }
}

export const formatDate = (date: Date | string, showYear = true) => {
    if (typeof date === "string") {
        date = new Date(date)
    }
    return date.toLocaleDateString('de-DE', {
        month: "short",
        day: "numeric",
        ...(showYear && { year: "numeric" })
    }) as string;
}

export const dateCompare = (sort?: "asc" | "desc") => {
    return (a: Gallery, b: Gallery) => {
        if (b.date_begin !== undefined && a.date_begin !== undefined) {
            if (sort === "desc") return +new Date(b.date_begin) - +new Date(a.date_begin)
            return +new Date(a.date_begin) - +new Date(b.date_begin)
        }
        return 0
    }
}