import { Style } from "../Globals"

interface Props {
  message: string
  title: string
}

const Message = ({ title, message }: Props) => {
  return (
    <div>
      <h2 className={`${Style.SubTitle} mt-4`}>{title}</h2>
      <p className="mt-4">
        {message}
      </p>
    </div>
  )
}

export default Message