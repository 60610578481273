import Dashboard from './Dashboard'
import Error from './ErrorPage'

import TripsContainer from './Trip/Container'
import NewTrip from './Trip/New'
import Trips from './Trip/List'

import GalleriesContainer from './Gallery/Container'
import NewGallery from './Gallery/New'
import Galleries from './Gallery/List'

import NewsContainer from './News/Container'
import NewNews from './News/New'
import News from './News/List'
 
const routes = [
  { 
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    element: <TripsContainer />,
    errorElement: <Error />,
    children: [
      { path: "trips", element: <Trips /> },
      { path: "trips/new", element: <NewTrip /> },
      { path: "trips/:id/edit", element: <NewTrip /> }   
    ]
  },
  {
    element: <GalleriesContainer />,
    children: [
      { path: "galleries", element: <Galleries /> },
      { path: "galleries/new", element: <NewGallery /> },
      { path: "galleries/:id/edit", element: <NewGallery /> }   
    ]
  },
  {
    element: <NewsContainer />,
    children: [
      { path: "news", element: <News /> },
      { path: "news/new", element: <NewNews /> },
      { path: "news/:id/edit", element: <NewNews /> }   
    ]
  },
]

export default routes