import { Trip } from "../../service/dto.types" 
import DefaultPhoto from "../../images/default-photo.jpg"

export const renderThumb = (trip: Trip) => {
  return <div className="w-full md:w-auto md:h-32 aspect-video bg-cover bg-center" style={{ backgroundImage: `url(${
    (!trip || !trip.images || trip.images.length < 1) ? DefaultPhoto : getMainImageThumb(trip)
  })`}}></div>
}

const getMainImageThumb : (trip: Trip)=>string = (trip) => {
  if(!trip || !trip.images || trip.images.length < 1) { return " " }
  
  const image = trip.images.filter(i => i.is_main).length > 0 ? 
    trip.images.filter(i => i.is_main)[0] : 
    trip.images[0]

  if(!image.thumb) return ""
  else{
    return image.thumb
  }
}