import { MouseEvent, useReducer, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

import { Trip, Gallery, Image } from '../../service/dto.types';
import * as GalleryService from '../../service/gallery.service'
import { getDateRange, parseTrip } from '../../service/trip.service'
import * as ApiService from '../../service/api.service'

import SubmitButton from '../../ui/buttons/ButtonSubmit'
import GalleryImage from './GalleryImage'

import { useSession } from "./Container";

// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
registerLocale("de", de);

const ACTIONS = {
  INIT: "INIT",
  SET_GALLERY: "SET_GALLERY",
  SET_LOADING: "SET_LOADING",
  UNSET_LOADING: "UNSET_LOADING",
}

interface ILoadingImg extends Image {
  id: number,
  isLoading: boolean,
}

interface IState {
  gallery: Gallery,
  isNewGallery: boolean,
  galleryHasLoaded: boolean,
  imagesLoading: ILoadingImg[],
}

interface IAction {
  type: string,
  payload?: any,
}

const NewGallery = () => {
  const initialState: IState = {
    gallery: GalleryService.getNewGallery(),
    isNewGallery: true,
    galleryHasLoaded: false,
    imagesLoading: [],
  }

  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ACTIONS.INIT:
        return initialState
      case ACTIONS.SET_GALLERY:
        if (action.payload.isNewGallery !== undefined) {
          return {
            ...state,
            gallery: action.payload.gallery,
            isNewGallery: action.payload.isNewGallery,
            galleryHasLoaded: true
          }
        }
        else {
          return { ...state, gallery: action.payload.gallery }
        }
      case ACTIONS.SET_LOADING:
        return { ...state, imagesLoading: [...state.imagesLoading, action.payload.imageLoading] }
      case ACTIONS.UNSET_LOADING:
        return { ...state, imagesLoading: state.imagesLoading.filter((image) => image.id !== action.payload.id) }
      default:
        console.log("Unknown action: " + action.type)
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { id: galleryId } = useParams();
  const { token, logout } = useSession();

  // State
  const [images, setImages] = useState<FileList>()
  const imagesList = images ? [...images] : [];
  const [showLoader, setShowLoader] = useState(false)
  const [relatedTripId, setRelatedTripId] = useState(0)

  // Initialize Gallery
  const [trips, setTrips] = useState<Trip[]>()

  const [returnToOverview, setReturnToOverview] = useState(false)

  // React router
  const navigate = useNavigate();

  // Form Validation
  const form = useForm({ mode: 'onBlur'});

  useEffect(() => {

    // Set gallery has loaded to false
    dispatch({ type: ACTIONS.INIT })

    const loadGallery = async () => {
      try {
        if (!galleryId) throw Error("Galerie ID nicht vorhanden!")

        ApiService.fetchGalleryById(galleryId)
        .then((gallery) => {
          if (!gallery) throw Error("Galerie konnte nicht gefunden werden.")

          // Parse gallery and save state
          dispatch({ type: ACTIONS.SET_GALLERY, payload: { gallery: GalleryService.parseGallery(gallery), isNewGallery: false } })

          form.reset()
          if(gallery.trip_id) setRelatedTripId(gallery.trip_id)
        })

      } catch (error: any) {
        toast.error(error.message || "Fehler beim Laden der Galerie.")
        setReturnToOverview(true)
      }
    }

    const loadTrips = async () => {
      const data = await ApiService.fetchTrips()
      if(!data) return

      // parse and set
      setTrips(data.map(t => parseTrip(t)))
    }

    if (galleryId) {
      loadGallery()
    } else {
      dispatch({ type: ACTIONS.SET_GALLERY, payload: { gallery: GalleryService.getNewGallery(), isNewGallery: true } })
    }

    loadTrips()

  }, [galleryId, form])

  // Handle field change
  const handleFieldChange = (event: any) => {
    if (state.gallery) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      dispatch({ type: ACTIONS.SET_GALLERY, payload: { gallery: { ...state.gallery, [name]: value } } })
    }

    form.clearErrors()
  }

  const addImageToGallery = (galleryId: string, image: File): Promise<boolean> => {
    const img: ILoadingImg = {
      id: Math.floor(Math.random() * 1000),
      isLoading: true,
    }

    dispatch({ type: ACTIONS.SET_LOADING, payload: { imageLoading: img } })

    return new Promise((resolve, reject) => {
      if (!image) reject(Error())

      const formData = new FormData();
      formData.append("images", image);

      ApiService.addImagesToGallery(galleryId, formData, token).then(() => {
        console.log("unset image")
        dispatch({ type: ACTIONS.UNSET_LOADING, payload: { id: img.id } })
        resolve(true)
      }
      ).catch((err) => {
        reject(err)
      })
    })
  }

  // Handle submit of gallery
  const onSubmit = async () => {
    setShowLoader(true)
  
    const relatedTrip = trips?.find(t => t.id === relatedTripId)
    if (!relatedTrip) {
      return form.setError("relatedTrip", { message: "Bitte wählen Sie eine gültige zugehörige Reise an" })
    }

    // Create the form data
    const formData = new FormData();
    formData.append('gallery', JSON.stringify({
      id: state.gallery.id,
      title: state.gallery.title,
      description: state.gallery.description,
      date_begin: relatedTrip.date_begin,
      date_end: relatedTrip.date_end,
    }));

    // Check if authenticated
    ApiService.checkToken(token).then(() => {
      if (!state.isNewGallery && galleryId) {

        // Modify existing gallery
        ApiService.updateGallery(galleryId.toString(), formData, token).then(() => {
          // Add images
          if (imagesList.length > 0) {
            console.log("Adding new images to trip...")

            const imagesFormData = new FormData()
            // Add the images to the form data
            imagesList.forEach((file) => {
              imagesFormData.append("images", file);
            });

            // API request to add images to gallery
            ApiService.addImagesToGallery(galleryId.toString(), imagesFormData, token).then(() => {
              toast.success("Galerie wurde erfolgreich bearbeitet")
              navigate("/admin/galleries")
            })
              .catch(() => {
                toast.error("Bilder konnten nicht hinzugefügt werden")
              setShowLoader(false)
            })

          } else {
            toast.success("Galerie wurde erfolgreich bearbeitet")
            navigate("/admin/galleries")
          }  
        }).catch(() => {
          toast.error("Galerie konnte nicht bearbeitet werden")
          setShowLoader(false)
        })
      } else {
        // Create new gallery

        // API call to create gallery
        ApiService.createGallery(formData, relatedTripId, token).then((gallery) => {
          if (!gallery || !gallery.id) throw Error("Galerie konnte nicht erstellt werden.")

          Promise.all(imagesList.map((image) => {
            if (!gallery.id) return Promise.resolve(true)
            return addImageToGallery(gallery.id.toString(), image)
          }))
            .then(() => {
            toast.success("Galerie wurde erfolgreich erstellt")
            navigate("/admin/galleries")
            }).catch((e) => {
              toast.error("Bilder konnten nicht hinzugefügt werden")
            }).finally(() => {
              setShowLoader(false)
            })
        })
          .catch(() => {
            toast.error("Galerie konnte nicht erstellt werden")
            setShowLoader(false)
        })
      }
    }).catch(() => {
      logout()
      toast("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.")
      setShowLoader(false)
    })
  }

  const handleSetAsTitle = async (event: MouseEvent) => {
    window.alert("Set as title!")
  }

  const handleDeleteImage = async (event: MouseEvent) => {
    if(window.confirm("Sind sie sicher?")){
      const target = (event.currentTarget as HTMLInputElement)
      const id = target.getAttribute("data-id")
  
      if (!id) return toast.error("Fehler beim Löschen des Bildes. Bild ID fehlt.")
  
      ApiService.deleteImageFromGallery(id, state.gallery, token).then(() => {
        toast.success("Bild erfolgreich gelöscht.")

        dispatch({ type: ACTIONS.SET_GALLERY, payload: { gallery: { ...state.gallery, images: state.gallery.images?.filter(img => img.id !== parseInt(id)) } } })
      })
      .catch(() => {
        toast.error("Fehler beim Löschen des Bildes.")
      })
    }
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImages(event.target.files ? event.target.files : new FileList())
  }

  const handleChangeTrip = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    
    setRelatedTripId(parseInt(value))

    const relatedTrip = trips?.find(t => t.id === parseInt(value))
    if (relatedTrip && relatedTrip.title) {
      dispatch({ type: ACTIONS.SET_GALLERY, payload: { gallery: { ...state.gallery, title: relatedTrip.title } } })
    }
  }

  const labelClass = "block text-gray-700 text-sm font-bold mb-2";
  const inputStyle = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  const buttonStyle = "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
  const errorStyle = "text-sm text-red-500"

  return (
    returnToOverview ? navigate("/admin/galleries") :
      state.galleryHasLoaded ?
      <div>
          <h2 className='text-2xl'>{!state.isNewGallery ? "Galerie bearbeiten" : "Neue Galerie erstellen"}</h2>
        <div className="w-full mt-4">
            <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

              <div className="form-group mb-4">
                <label htmlFor="relatedTrip" className={labelClass}>Zugehörige Reise</label>
                <select
                  {...form.register("relatedTrip", { required: true })}
                  className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleChangeTrip} value={relatedTripId}>
                  <option key="default" value="">Bitte wählen Sie eine Reise aus!</option>
                  {trips && trips.map((trip) => (
                    <option value={trip.id} key={trip.id}>{getDateRange(trip)}: {trip.title}</option>
                  ))}
                </select>
                {form.formState.errors.relatedTrip && <span className={errorStyle}>Bitte geben Sie eine zugehörige Reise ein!</span>}
              </div>


              <div className="form-group mb-4">
                <label htmlFor="title" className={labelClass}>Titel</label>
                <input type="text"
                  {...form.register("title")}
                  className={inputStyle}
                  id="title"
                  placeholder="Bitte geben Sie eine Überschrift zur Galerie ein"
                  value={state.gallery.title}
                  onChange={handleFieldChange}
                />

                {form.formState.errors.title && <span className={errorStyle}>Bitte geben Sie einen Titel ein</span>}
              </div>      

            <div className="form-group mb-4">
              <h2>Bilder</h2>
              <div className="flex flex-wrap">
                {
                  // Map over savedImages and display them 
                    state.gallery.images && state.gallery.images.length > 0 ?
                      state.gallery.images.map((image) => {
                        return (
                          <GalleryImage key={image.id} image={image} handleDeleteImage={handleDeleteImage} handleSetAsTitle={handleSetAsTitle} />
                        )
                      })
                      : <p className='basis-full italic pb-4'>Noch keine Bilder gespeichert!</p>
                }

                  {
                    state.imagesLoading.map((image) => {
                      return (
                        <GalleryImage key={image.id} image={image} isLoading={image.isLoading} />
                      )
                    })
                  }

              </div>
              <label htmlFor="images" className={labelClass}>Bilder</label>
              <input type="file"
                className={inputStyle}
                id="images"
                name="images"
                accept="image/*"
                onChange={handleImageChange}
                multiple />
            </div>

            <SubmitButton
                text={!state.isNewGallery ? "Änderungen speichern" : "Galerie erstellen"}
              loading={showLoader}
              disabled={showLoader}
              className={`${buttonStyle} disabled:bg-slate-200 disabled:cursor-not-allowed`}
            />

              {
                state.imagesLoading && state.imagesLoading.length > 0 &&
                <span className="pl-2">Bilder werden hochgeladen: {state.imagesLoading.length} {state.imagesLoading.length > 1 ? "Bilder" : "Bild"}</span>
              }

          </form>
        </div>
      </div> : <div>Das Formular wird geladen...</div>
  )
}

export default NewGallery
