import { Trip, ITag } from './dto.types'

interface TripGroup {
    [key: string]: Trip[]
}

export const isAbgesagt = (trip: Trip): boolean => {
    return trip.tags?.includes("Abgesagt") || false
}

export const isAusgebucht = (trip: Trip): boolean => {
    return trip.tags?.includes("Ausgebucht") || false
}

export const isVoranzeige = (trip: Trip): boolean => {
    return (!isPastTrip(trip) && getDaysLength(trip) > 50)
}
// Initialize a new trip
export const getNewTrip = (): Trip => {
    return {
        id: undefined,
        title: "",
        description: "",
        date_begin: undefined,
        date_end: undefined,
        detail: "",
        services: "",
        images: [],
        tags: [],
        price: 0,
        prices: [],
    }
}

export const getDaysLength = (trip?: Trip, opts?: { date_begin?: Date; date_end?: Date; }): number => {
    const getDays = (a: Date, b: Date): number => {
        const diff = b.getTime() > a.getTime() ? b.getTime() - a.getTime() : a.getTime() - b.getTime()
        return Math.ceil(diff / (1000 * 3600 * 24)) || 1
    }

    if (trip && trip.date_begin && trip.date_end) {
        try {
            return getDays(new Date(trip.date_begin), new Date(trip.date_end))
        }
        catch (err) {
            return 0
        }
    }

    if (opts?.date_begin && opts?.date_end) {
        return getDays(opts.date_begin, opts.date_end)
    }

    return 0
}

export const formatDate = (date: Date | string, showYear = true) => {
    if (typeof date === "string") {
        date = new Date(date)
    }
    return date.toLocaleDateString('de-DE', {
        month: "short",
        day: "numeric",
        ...(showYear && { year: "numeric" })
    }) as string;
}

export const formatPrice = (trip: Trip) => {
    if (trip.price !== undefined) {
        return "CHF " + trip.price + ".- "
    }

    return "Keine Angabe"
}
export const getDateRange = (trip: Trip): string => {
    if (trip.date_begin !== undefined && trip.date_end !== undefined) {
        if (getDaysLength(trip) > 1) {
            return formatDate(trip.date_begin, trip.date_begin.getFullYear() !== trip.date_end.getFullYear()) + " – " + formatDate(trip.date_end)
        }
        if (getDaysLength(trip) === 1) {
            return formatDate(trip.date_begin)
        }
    }
    return "Keine Angabe"
}

export const getTripDaysString = (trip: Trip): string => {
    if (trip.date_begin !== undefined && trip.date_end !== undefined) {
        const days = getDaysLength(trip);
        return days > 1 ? days + " Tage" : days + " Tag"
    }
    return "Keine Angabe"
}

export const parseTrip = (trip: any): Trip => {
    return {
        ...trip,
        date_begin: trip.date_begin ? new Date(trip.date_begin) : undefined,
        date_end: trip.date_end ? new Date(trip.date_end) : undefined,
        prices: trip.up_prices,
        tags: trip.tags ? trip.tags.split(",") : []
    }
}

/* Group trips by month */
export const groupTrips = (trips: Trip[] | undefined) => {
    if (trips !== undefined) {
        return trips?.sort((a, b) => {
            if (!b.date_begin) return 1
            if (!a.date_begin) return -1
            return a.date_begin.getTime() - b.date_begin.getTime()
        }).reduce((cache, t) => {
            if (!t.date_begin) return cache;

            const property = new Date(t.date_begin).toLocaleDateString('de-CH', {
                month: "long"
            }) + " " + new Date(t.date_begin).getFullYear()
            if (property in cache) {
                return { ...cache, [property]: (cache[property as keyof object] as Trip[]).concat(t) }
            }
            return { ...cache, [property]: [t] }
        }, {}) as TripGroup;
    }
    return {}
}

/* Group trip by year */
export const groupByYear = (trips: Trip[] | undefined) => {
    if (trips !== undefined) {
        return trips?.sort((a, b) => {
            if (!b.date_begin) return 1
            if (!a.date_begin) return -1
            return a.date_begin.getTime() - b.date_begin.getTime()
        }).reduce((cache, t) => {
            if (!t.date_begin) return cache;

            const property = new Date(t.date_begin).toLocaleDateString('de-CH', {
                year: "numeric"
            })
            if (property in cache) {
                return { ...cache, [property]: (cache[property as keyof object] as Trip[]).concat(t) }
            }
            return { ...cache, [property]: [t] }
        }, {}) as TripGroup;
    }
    return {}
}

export interface IState {
    trips?: Trip[];
    selectedTrips?: Trip[];
    tags?: ITag[];
    selectedTags?: ITag[];
    tripsLoaded?: boolean;
}

export interface IAction {
    type: string;
    payload: IState;
}

export const ACTIONS = {
    INIT_TRIPS: 'init-trips',
    SET_TRIPS: 'set-trips',
    SET_SELECTED_TRIPS: 'set-selected-trips',
    SET_TRIPS_LOADED: 'set-trips-loaded',
    SET_TAGS: 'set-tags',
    SET_SELECTED_TAGS: 'set-selected-tags',
};

export function isPastTrip(trip: Trip): boolean {
    return trip.date_begin !== undefined && trip.date_begin.getTime() < (new Date()).getTime()
}

export const dateCompare = (sort?: "asc" | "desc") => {
    return (a: Trip, b: Trip) => {
        if (b.date_begin !== undefined && a.date_begin !== undefined) {
            if (sort === "desc") return +new Date(b.date_begin) - +new Date(a.date_begin)
            return +new Date(a.date_begin) - +new Date(b.date_begin)
        }
        return 0
    }
}