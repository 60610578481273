import Logo from './Logo'
import Slogan from './Slogan'
import { ReactComponent as DoubleUpArrow } from './ui/icons/double-up-arrow.svg'

import Car from './cars/car.svg'
import Vector1 from './images/bg/Vector1.svg'
import Vector2 from './images/bg/Vector2.svg'
import Vector3 from './images/bg/Vector3.svg'
import Vector4 from './images/bg/Vector4.svg'
import Vector6 from './images/bg/Vector6.svg'
import Vector7 from './images/bg/Vector7.svg'
import Vector8 from './images/bg/Vector8.svg'

import './Triangle.css'
import { forwardRef } from 'react'

type Props = {
  executeScroll: () => void,
}

const HomePage = forwardRef((props: Props, ref: any) => {
  const svgStyle = `absolute w-full mx-auto bg-no-repeat`

  return (
    <header ref={ref}>
        <div className="relative flex flex-col h-screen w-full bg-gradient-to-b from-sky-500 via-white to-white" onClick={() => props.executeScroll()}>
          <div className='flex flex-col flex-wrap grow relative overflow-hidden'>
            {/* Logo & Slogan */}
            <div className="flex flex-wrap flex-col absolute top-0 h-full w-full z-10 pt-16">
              <div className="m-auto">
                <Logo />
                <Slogan className="pl-20 pr-12" />
              </div>

              <div className="flex flex-wrap flex-col grow items-center justify-center md:w-1/2 mx-auto mb-8 mt-auto text-center cursor-pointer">
                <div className='animate-bounce-slow text-5xl text-white mx-4'>
                  <button className={`flex group items-center py-4 px-8 rounded-full text-4xl text-white bg-blue-700 hover:bg-blue-600 cursor-pointer`} type="button">Kommen Sie mit uns auf Reise!</button>
                  <DoubleUpArrow className='fill-white mx-auto w-16 rotate-180 mt-4' />
                </div>
              </div>
            </div>

            <div className={`${svgStyle} h-full bg-top vector1 vector-bg`} style={{ backgroundImage: `url(${Vector1})` }} ></div>
            <div className={`${svgStyle} h-full bg-top vector2 vector-bg`} style={{ backgroundImage: `url(${Vector2})` }} ></div>
            <div className={`${svgStyle} h-full bg-top vector3 vector-bg`} style={{ backgroundImage: `url(${Vector3})` }} ></div>
            <div className={`${svgStyle} h-full bg-top vector4 vector-bg`} style={{ backgroundImage: `url(${Vector4})` }} ></div>
            <div className={`${svgStyle} h-full bg-top vector6 vector-bg`} style={{ backgroundImage: `url(${Vector6})` }} ></div>
            <div className={`${svgStyle} h-full bg-top vector7 vector-bg`} style={{ backgroundImage: `url(${Vector7})` }} ></div>
            <div className={`absolute mx-auto bottom-0 vector8 w-full vector-bg`} style={{ backgroundImage: `url(${Vector8})` }} ></div>
            <div className={`absolute mx-auto bg-no-repeat w-full bg-center car`} style={{ backgroundImage: `url(${Car})` }} ></div>
          </div>
        </div>
    </header>
  )
})

export default HomePage