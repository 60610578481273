import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react'
import ArrowUp from './ui/ArrowUp'

import Headline from './Nav'
import Footer from './Footer'
import ContactForm from "./ContactForm";

const ScrollWrapper = ({children} : any) => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children
} 

const Page = () => {

  let [showContactForm, setShowContactForm] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="w-full overflow-auto text-lg bg-white">
      <ArrowUp />

      <Headline showMenu={true} />

      <ScrollWrapper>
        <Outlet />
      </ScrollWrapper>

      <Footer setShowContactForm={setShowContactForm} />

      <ContactForm show={showContactForm} set={setShowContactForm}  />
    </div>
  )
}

export default Page
