import { useEffect, useState } from "react"
import { Style } from "./Globals"
import { dateCompare, formatDate } from "./service/gallery.service"
import NavMagicContainer from "./NavMagicContainer"
import { fetchGalleries } from "./service/api.service"
import { Gallery } from "./service/dto.types"

const Galleries_2 = () => {

  const [galleries, setGalleries] = useState([] as Gallery[])

  useEffect(() => {
    fetchGalleries().then((data) => {
      setGalleries(data)
    })
  }
    , [])

  return (
    <div>
      <NavMagicContainer />
      <div className="">
        <h1 className={`${Style.Title} pt-16 text-center`}>Galerien</h1>
        {
          // map galleries and link to gallery page
          galleries.sort(dateCompare("desc")).map((gallery) => {
            return (
              <div className="flex items-stretch bg-slate-100 my-8">
                {
                  gallery.images &&
                  <div className="bg-cover bg-center basis-1/3" style={{ backgroundImage: `url(${gallery.images[0].thumb})` }}></div>
                }

                <div className='my-8 p-8 md:w-2/3 m-auto'>
                  <h2 className='text-3xl mb-4'>{gallery.title}</h2>
                  {gallery.date_begin && <p className='text-left'>{formatDate(gallery.date_begin)}</p>}
                  <p className='text-left'>{gallery.description}</p>
                  <a href={`/galleries/${gallery.id}`} className='underline hover:no-underline'>Zur Galerie</a>
                </div>
              </div>
            )
          })

        }
      </div>
    </div>
  )
}

export default Galleries_2