import { FieldError } from "react-hook-form";
import PhoneNumberInput from "react-phone-number-input/react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import './PhoneInput.css'
import { useEffect } from "react";

const inputStyle = `
  border border-[#0000003b]
  py-4
  bg-transparent
  text-[1rem] mt-2 appearance-none rounded w-full px-3 text-gray-700 
  leading-tight shadow`;
const errorStyle = `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`;

type Props = {
  error: FieldError | undefined,
  errorMsg: string,
  control: any,
  name: string,
  className?: string,
  required?: boolean,
  placeholder?: string,
}

const PhoneInput = ( props : Props) => {
  useEffect(() => {
    Array.from(document.getElementsByClassName("PhoneInputCountrySelect")).forEach((el) => {
      el.setAttribute('tabindex', '-1')
    })
  }, [])
  return (
    <div className={`${props.className}`}>
      <label>Handynummer (oder Telefonnummer)</label>
      <PhoneNumberInput 
        // placholder
        placeholder={props.placeholder}
        error={props.error} 
        control={props.control} 
        defaultCountry='CH'
        className={`${inputStyle} group`}
        name={props.name}
        rules={{ 
          required: props.required ?? false,
          validate: (value: any) => isValidPhoneNumber(value)
                 }}
        withCountryCallingCode={true}
      />
      { props.error && <span className={errorStyle}>{props.errorMsg}</span> }


    </div>

  )
}

export default PhoneInput