import { Style } from "../Style"
import { useEffect, useReducer } from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import { Trip, ITag } from "../../service/dto.types"

import * as ApiService from "../../service/api.service"
import { ACTIONS, IState, IAction, parseTrip, isPastTrip, dateCompare  } from "../../service/trip.service"

import { useSession as Session } from "./Container";
import TripFilter from "../../TripFilter"
import TripListItem from "./TripListItem";


const List = () => {
  const { token, logout } = Session()

  const getSelectedTrips = (selectedTags: ITag[] | undefined, trips: Trip[] | undefined) => {
    if (!trips) return []
    if (!selectedTags) return trips

    return selectedTags.filter(t => t.isActive === true).length > 0 ?
      trips.filter(trip => {
        // if all selected tags are inside trip.tags[] then return trip
        return selectedTags.filter(tag => tag.isActive === true).every(tag => trip.tags?.includes(tag.name))
      })
      : trips
  }
  
  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ACTIONS.SET_TRIPS:
        return { ...state, trips: action.payload.trips, tripsLoaded: true }
      case ACTIONS.SET_TRIPS_LOADED:
        return { ...state, tripsLoaded: action.payload.tripsLoaded }
      case ACTIONS.SET_SELECTED_TRIPS:
        return { ...state, selectedTrips: getSelectedTrips(state.selectedTags, state.trips) }
      case ACTIONS.SET_TAGS:
        return { ...state, tags: action.payload.tags }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    selectedTags: [] as ITag[],
    selectedTrips: [] as Trip[],
    tripsLoaded: false,
    trips: [] as Trip[],
  })

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_TRIPS_LOADED, payload: { tripsLoaded: false }})

    ApiService.fetchTrips().then((fetchedTrips) => {
      let trips = fetchedTrips.sort(dateCompare("desc")).map(trip => parseTrip(trip)) // Parse trips
      console.log(trips)
      dispatch({ type: ACTIONS.SET_TRIPS, payload: { trips: trips }})
      dispatch({ type: ACTIONS.SET_TRIPS_LOADED, payload: { tripsLoaded: true }})

      // Initialize Tags
      let tags = trips.reduce((acc, curr) => {
        return curr.tags ? [...new Set([...acc, ...curr.tags])] : acc
      }, [] as string[])
      dispatch({ type: ACTIONS.SET_TAGS, payload: { tags: tags.map(t => { return { name: t, isActive: false } as ITag }) }})
    })
    .catch(() => {
      toast.error("Fehler beim Laden der Reisen")
      dispatch({ type: ACTIONS.SET_TRIPS_LOADED, payload: { tripsLoaded: true }})
    })
  }, [])

  // Delete trip
  const deleteTrip = async (tripId: number) => {
    ApiService.checkToken(token)
    .then((data) => {
      if(!data) { throw new Error() }

      //Delete trip
      ApiService.deleteTrip(tripId.toString(), token)
      .then(() => {        
        // Success
        dispatch({ type: ACTIONS.SET_TRIPS, payload: { trips: state.trips?.filter(trip => trip.id !== tripId) }})
        toast("Löschen erfolgreich!")
      })
      .catch((error) => {
        toast(error.message || "Löschen fehlgeschlagen!")
      })
    })
    .catch(() => {
      logout()
      toast("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.") 
    })
  }

  // handle delete Trip
  const handleDeleteTrip = async (tripId: number) => {
    confirmAlert({
      title: 'Sind Sie sicher, dass Sie diese Reise löschen möchten?',
      message: 'Sie können diese Aktion nicht rückgängig machen.',
      buttons: [
        { label: 'Ja', onClick: () => deleteTrip(tripId) },
        { label: 'Abbrechen', onClick: () => {} }
      ]
    });
  }

  const setTagList = (tags : ITag[]) : void => {
    dispatch({ type: ACTIONS.SET_TAGS, payload: { tags: tags } })
  }

  const isTagSelected = (trip: Trip) : boolean => {
    if(!state.tags) return false
    return state.tags?.filter(tag => tag.isActive).map(tag => tag.name).every(selTag => trip.tags?.includes(selTag))
  }


  const tripsFiltered = (isPast: boolean = false) : Trip[] => {
    if(!state.trips) return []
    return state.trips && state.trips.filter(trip => {
      return (isPast === isPastTrip(trip)) && isTagSelected(trip)
    }).map((trip) => {
      return trip
    })
  }


  return (
    <div>
      <h2 className={Style.Title2}>Reisen</h2>
      
      {
        state.tags && <TripFilter setTagList={setTagList} tagList={state.tags} />
      }

      {
        !state.tripsLoaded ? <div>Lade Reisen...</div> :
        <div>
          <h2 className="text-center text-2xl p-4 mt-8">Kommende Reisen</h2>
          {
            // List upcoming trips, filtered
            tripsFiltered().length > 0 ?
              tripsFiltered().map(t => <TripListItem handleDeleteTrip={handleDeleteTrip} trip={t} />)
              : <div className="text-center">Keine Reisen vorhanden.</div>
          }

          <h2 className="text-center text-2xl p-4 mt-8">Vergangene Reisen</h2>
          {
            // List past trips, filtered
            tripsFiltered(true).length > 0 ?
            tripsFiltered(true).map(t => <TripListItem handleDeleteTrip={handleDeleteTrip} trip={t} />)
            : <div className="text-center">Keine Reisen vorhanden.</div>
          }
        </div>
      }
      </div>
  )
}

export default List