import { useEffect, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form'

const inputStyle = `appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow`;
const errorStyle = `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`;

interface Props {
  label: string
  name: string,
  required: boolean,
  error?: FieldError,
  errorMsg: string,
  register: UseFormRegister<any>,
  className?: string,
}

const FormElement = ({ className, register, label, name, required = false, error, errorMsg }: Props) => {
  let [time, setTime] = useState(Date.now())
  useEffect(() => {
    setInterval(() => {
      setTime(Date.now())
    }, 1000 * 60)
  }, [])

  return (
    <div className={className}>
      <label htmlFor={name} className='mt-4'>{label}</label>
      <img className='w-48 h-16 mx-auto' src={`/api/others/captcha-generate?${time}`} alt="Captcha" />
      <div>Antwort:</div>
      <input {...register(name, { required: required })}
        className={`${inputStyle} mt-2`}  />
        { error && <span className={errorStyle}>{errorMsg}</span> }
    </div>)
}

export default FormElement