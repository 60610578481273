import { Link } from "react-router-dom"
import { useEffect, useReducer } from 'react'
import { Trip, ITag } from './service/dto.types'
import TripComponent from './TripAvatar'
import TripFilter from './TripFilter'
import { Style } from "./Globals"

import { ACTIONS, IState, IAction, parseTrip, groupTrips, groupByYear, isPastTrip, isVoranzeige, isAbgesagt } from "./service/trip.service"
import * as ApiService from "./service/api.service"
import NavMagicContainer from "./NavMagicContainer"

const Trips = () => {
  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ACTIONS.SET_TRIPS:
        return { ...state, trips: action.payload.trips, tripsLoaded: true }
      case ACTIONS.SET_TAGS:
        return { ...state, tags: action.payload.tags }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    trips: [] as Trip[],
    selectedTrips: [] as Trip[],
    tripsLoaded: false,
    tags: [] as ITag[],
  })

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_TRIPS_LOADED, payload: { tripsLoaded: false } })

    /* API load trips */
      ApiService.fetchTrips().then((fetchedTrips) => {
        let trips = fetchedTrips.map(trip => parseTrip(trip)) // Parse trips
  
        // Initialize Tags
        let tags = trips.reduce((acc, curr) => {
          return curr.tags ? [...new Set([...acc, ...curr.tags])] : acc
        }, [] as string[]).filter(t => t !== "Abgesagt" && t !== "Ausgebucht")
  
        dispatch({ type: ACTIONS.SET_TAGS, payload: { tags: tags.map(t => { return { name: t, isActive: false } as ITag }) } })
  
        dispatch({ type: ACTIONS.SET_TRIPS, payload: { trips: trips } })
        
      }).catch((err) => {
        console.error("Error fetching trips.")
      })
  }, [])

  const setTags = (tags: ITag[]): void => {
    dispatch({ type: ACTIONS.SET_TAGS, payload: { tags: tags } })
  }

  const isFilterSelected = (trip: Trip): boolean => {
    if (!state.tags) return false
    return state.tags?.filter(tag => tag.isActive).map(tag => tag.name).every(selTag => trip.tags?.includes(selTag))
  }

  const FilterOpts = {
    VORANZEIGE: 'VORANZEIGE',
    NO_VORANZEIGE: 'NO_VORANZEIGE',
    ALL: 'ALL',
  }

  const dateCompare = (a: Date, b: Date): number => {
    if (a.getTime() > b.getTime()) return 1
    if (a.getTime() < b.getTime()) return -1
    return 0
  } 

  const tripDateCompare = (a: Trip, b: Trip): number => {
    if (!a.date_begin || !b.date_begin) return 0
    return dateCompare(a.date_begin, b.date_begin)
  } 

  const getUpcomingTrips = (opts?: { filter: string }): Trip[] => {
    if (!state.trips) return []

    const filterTrip = (trip: Trip): boolean => {
      switch (opts?.filter) {
        case FilterOpts.VORANZEIGE:
          return isVoranzeige(trip)
        case FilterOpts.NO_VORANZEIGE:
          return !isVoranzeige(trip)
        default:
          return true
      }
    }

    return state.trips.filter(trip => !isPastTrip(trip) && isFilterSelected(trip) && filterTrip(trip)).sort(tripDateCompare)
  }

  const upcomingTrips = getUpcomingTrips()

  const voranzeigeSorted = Object.entries({...groupByYear(getUpcomingTrips({ filter: FilterOpts.VORANZEIGE }))}).map(([key, value]) => {
    const tripsSorted = value.sort((a, b) => {
      // by date begin
      return tripDateCompare(a, b)
    })
    return { key, value: tripsSorted }
  })

  const noVoranzeigeSorted = Object.entries({...groupTrips(getUpcomingTrips({ filter: FilterOpts.NO_VORANZEIGE }))}).map(([key, value]) => {
    const tripsSorted = value.sort((a, b) => {
      // by date begin
      return tripDateCompare(a, b)
    })
    return { key, value: tripsSorted }
  })

  const upcomingTripsByGroup = [
    ...noVoranzeigeSorted,
    ...voranzeigeSorted,
  ]

  return (
    <div>
      <NavMagicContainer className="bg-white" />
      <div className="container m-auto px-4 py-16">
        <h1 className={`${Style.Title} text-center`}>Unsere Reisen</h1>
        {
          !state.tripsLoaded ? 
              <div className="flex justify-center text-2xl text-slate-500 font-gili ">
                <span className="p-4 mt-8 rounded border border-slate-500">Reisen werden geladen...</span>
              </div>
          :
          (
            state.trips && state.trips.length > 0 ?
              <div>
                {
                  state.tags && state.tags.length > 1 &&
                  <TripFilter setTagList={setTags} tagList={state.tags} />
                }

                {
                  upcomingTrips.length < 1 ?
                    <div className="flex justify-center text-2xl text-slate-500 font-gili ">
                      <span className="p-4 mt-8 rounded border border-slate-500">
                        Für die ausgewählten Reisetypen sind leider keine Reisen vorhanden!
                      </span>
                    </div>
                    : <>
                      {
                        upcomingTripsByGroup.map((trip) => {
                          return (
                            <div key={trip.key}>
                              <h2 className={`${Style.SubTitle} p-4 mt-8 text-center`}>im <strong>{trip.key}</strong></h2>

                              <div className="flex flex-wrap justify-center">
                                {
                                  trip.value.map((trip) => {
                                    return (
                                      <div className="basis-full md:basis-1/2 lg:basis-1/4 mb-4 md:p-4" key={trip.id} >
                                        {
                                          isAbgesagt(trip) ? <TripComponent trip={trip} />
                                            : <Link to={`/trips/${trip.id}`}>
                                              <TripComponent trip={trip} />
                                            </Link>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          )
                        }
                        )
                      }
                    </>
                }

                {
                  <div className="text-center p-4 mt-8 underline hover:no-underline">
                    <Link to="/trips/past">Vergangene Reisen anzeigen</Link>
                  </div>
                }
              </div>
              : <div className="flex justify-center text-2xl text-slate-500 font-gili ">
                <span className="p-4 mt-8 rounded border border-slate-500">Keine Reisen vorhanden!</span>
              </div>
          )
        }
      </div>
    </div>

  )
}

export default Trips