const Dashboard = () => {
  
  return (
    <div>
      <div className="flex container m-auto mt-4 p-4">
        <h2>Willkommen im Backend von ank-tours.ch.</h2>
      </div>
    </div>
  )
}

export default Dashboard