import { Style } from "../Globals.js"
import { UseFormReturn, FieldValues } from "react-hook-form";

interface Props {
  form: UseFormReturn<FieldValues, any>,
  value: string | undefined,
  handleFieldChange: (event: any) => void,
  fieldName: string,
  isRequired: boolean,
  message: string,
  label: string,
}

const InputField = ({label, fieldName, value, form, handleFieldChange, message, isRequired = false}: Props) => {
  return (
    <div className="w-full">
      <label htmlFor={fieldName} className={Style.Label}>{label}</label>
      <input type="text"
        {...form.register(fieldName, { required: isRequired })}
        className={Style.Input}
        id={fieldName}
        placeholder={message}
        value={value}
        onChange={handleFieldChange}
      />

      {form.formState.errors[fieldName] && <span className={Style.Error}>{message}</span>}
    </div>
  )
}

export default InputField