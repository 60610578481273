import * as ApiService from './service/api.service'
import { useEffect, useState } from 'react'
import { News as INews } from './service/dto.types'
import { Style, formatDate } from './Globals'
import DocumentIcon from './ui/icons/icons8-new-document-48.png'

const News = () => {
  const [news, setNews] = useState<INews[]>()
  const [newsLoaded, setNewsLoaded] = useState(false)

  useEffect(() => {
    setNewsLoaded(false)
    ApiService.fetchNews().then((news) => {
      try{
        setNews(news)
        setNewsLoaded(true)
      }
      catch(error){
        console.log(error)
      } 
    }).catch((err) => {
      console.error("Error fetching news.")
    })
  }, [])

  return (
    newsLoaded && 
    news && news.length > 0 ? 
    <div className="container m-auto mt-8 py-8 px-8 lg:px-32">
        <h2 className={`${Style.Title} text-center`}>News</h2>
        <div className={`flex flex-wrap flex-col justify-between items-stretch md:flex-row mt-4`}>
          {
            news.sort((a, b) => { return !a.creation_date || !b.creation_date ? 0 : +new Date(b.creation_date) - +new Date(a.creation_date) }).slice(0, 3).map((news) => (
              <div className={`flex my-4 md:m-0 md:p-4`} key={news.id}>
                <div className={`p-4`}>
                  <p className={`text-sm`}>vom <strong>{news.creation_date && formatDate(news.creation_date)}</strong></p>
                  <h3 className={`${Style.TextColor} text-2xl mt-2`}>{news.title}</h3>
                  <p className='py-2'>{news.description}</p>
                  {
                    news.attachments && news.attachments.length > 0 ?
                      <div className='flex flex-col'>
                        {
                          news.attachments.map((attachment) => (
                            <div key={attachment.id}>
                              <a href={attachment.url} target="_blank" rel="noreferrer" className={Style.Link}>
                                <img src={DocumentIcon} alt='Document Icon' className='inline-block w-6 h-6 mr-2' />
                                {attachment.name}
                              </a>
                            </div>
                          ))
                        }
                      </div>
                      : <></>
                  }
                </div>
              </div>
            ))
          }  
        </div>
    </div>
    : <></>
  )
}

export default News