import { Style } from "../Style"
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { News } from "../../service/dto.types"

import * as ApiService from "../../service/api.service"
import * as NewsService from "../../service/news.service"
import { formatDate } from "../../service/trip.service"

import { useSession as Session } from "./Container";

const List = () => {

  const { token, logout } = Session()

  /* Token authentication */
  const [news, setNews] = useState<News[]>()

  useEffect(() => {
    ApiService.fetchNews().then((fetchedNews) => {
      // Parse news and set state
      setNews(fetchedNews.map(n => NewsService.parseNews(n)))
    })
  }, [])

  // Delete news
  const deleteNews = async (newsId: number) => {
    ApiService.checkToken(token).then(() => {
      //Delete news
      ApiService.deleteNews(newsId.toString(), token).then(() => {        
        // Success
        setNews(news?.filter(n => n.id !== newsId))
        toast.success("Löschen erfolgreich!")
      })
      .catch((error) => {
        toast.error(error.message || "Löschen fehlgeschlagen!")
      })
    })
    .catch(() => {
      logout()
      toast.error("Ihre Session ist abgelaufen! Bitte loggen Sie sich erneut ein.") 
    })
  }

  // handle delete News
  const handleDeleteNews = async (newsId: number) => {
    confirmAlert({
      title: 'Sind Sie sicher, dass Sie diese News löschen möchten?',
      message: 'Sie können diese Aktion nicht rückgängig machen.',
      buttons: [
        { label: 'Ja', onClick: () => deleteNews(newsId) },
        { label: 'Abbrechen', onClick: () => {} }
      ]
    });
  }

  return (
    <div>
      <h2 className={`${Style.Title2}`}>News</h2>
      {
        // List of news
        news && news.length ? news.map((n) => {
          return (
            <div className='flex flex-wrap flex-col sm:flex-row gap-4 justify-between items-center bg-slate-200 p-4 mb-4' key={n.id}>
              <div>
                <h3 className='text-xl font-bold'>{n.title}</h3>
              </div>
              <div>
                <span className='text-sm'>{n.creation_date && "vom " + formatDate(n.creation_date)}</span>
              </div>
              <div>
                {n.attachments && <span className="text-sm sm:mx-4">{n.attachments.length} {n.attachments.length > 1 ? " Dateien" : " Datei"} angehängt</span>}
              </div>
              <div className="flex items-center basis-full sm:basis-auto ml-auto mt-4 md:mt-0">
                <div className="ml-auto">
                  <Link to={`/admin/news/${n.id}/edit`} state={{ data: n, edit: true }} className='bg-slate-800 text-white text-sm font-bold py-2 px-4 m-2'>Bearbeiten</Link>

                  <button className='bg-red-800 text-white text-sm font-bold py-2 px-4 ml-2'
                    onClick={() => { n.id && handleDeleteNews(n.id) }}>Löschen</button>
                </div>


              </div>
            </div>
          )
        }) : <div>Keine News vorhanden!</div>
      }

    </div>
  )
}

export default List