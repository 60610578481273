import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg'

interface Props {
  message: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  className?: string,
}

const ButtonBack = (props: Props) => {
  return (
    <button className={`${props.className} text-gray-400 hover:text-gray-500`} onClick={props.onClick}>
      <ArrowRight className='w-6 inline-block mr-2 -scale-x-100' />
      {props.message}
    </button>
  )
}

export default ButtonBack
