import { News } from './dto.types'

// Initialize a new trip
export const getNewNews = (): News => {
    return {
        id: undefined,
        title: "",
        description: "",
        attachments: [],
        creation_date: new Date()
    }
}

export const parseNews = (news: any): News => {
    return {
        ...news,
    }
}