// Admin style

const BgColor = "bg-blue-500"

export const Style = {
  Subtitle: "font-bold uppercase text-left underline mt-4",
  Title2: "font-bold uppercase underline",
  Input: `appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`,
  Label: `text-left block text-gray-700 text-md font-bold`,
  Button: `${BgColor} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`,
}