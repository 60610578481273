import { formatDate } from "./service/trip.service"

const borderColor = "border-blue-700"
const from = "from-blue-700"
const via = "via-blue-800"
const to = "to-blue-500"

const BgColor = `bg-blue-500`
const BgDark = `bg-slate-700`
const BgLight = `bg-gradient-to-tr from-blue-200 to-gray-100`

const TextColor = `text-blue-500`

const TextLight =
 `text-slate-700 `

const Link = `text-blue-500 hover:text-blue-800`

const Style = {
  BgColor, BgDark, BgLight,
  BgColor2: `bg-blue-800`,
  BgHover: `hover:bg-blue-800`,
  BorderColor: borderColor,
  Button: `${BgColor} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`,
  Button2: `${BgDark} hover:bg-slate-800 text-white font-bold py-2 px-4 rounded`,
  Color1: `sky-500`,
  Color2: `indigo-900`,
  Error: `text-sm text-red-500`,
  ErrorText: `text-sm text-red-500`,
  ErrorWithBg: `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`,
  from, via, to,
  Input: `appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow bg-white`,
  Label: `text-left block text-gray-700 text-md font-bold`,
  LabelInverted: `text-white`,
  Link,
  Picture: `shadow-md`,
  SubTitle: `text-2xl`,
  SubTitle2: `text-xl text-slate-800 tracking-wide`,
  SubTitleLeft: `pt-8 text-2xl font-bold underline mt-8`,
  TextColor,
  TextHighlight: `${TextColor} text-2xl`,
  TextLight,
  Title: `text-3xl sm:text-5xl tracking-wide font-gili`,
  TitleHuge: `text-4xl sm:text-5xl m-8 tracking-wide font-gili `,
}

export {
  formatDate,
  Style
}