import { Link, useLocation } from 'react-router-dom'
import Logo from '../logo.svg'
import { useState } from 'react'

const Headline = ({ logout }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const navItemClassName = `
    flex grow
    py-4 px-8
    text-lg 
    justify-center items-center 
    text-white font-bold whitespace-nowrap
    border-slate-600 border-b-4 hover:border-sky-600
    `;

  const location = useLocation();
  const path = location.pathname;

  const routeActiveStyle = (route) => {
      return path.startsWith(route) ? 'border-sky-600' : '';
  }

  return (
    <div>
      <div className="px-4 bg-slate-600">

        {/* Desktop */}
        <div className="container m-auto flex flex-wrap overflow-visible">
        <div className="basis-1/4 py-6">
          <Link to="/">
            <img src={Logo} alt="A.N.K. Tours Logo" className="min-w-[200px]" />
          </Link>
        </div>
        <div className="pl-4 flex grow">
            <Link to="/admin/trips" className={`${navItemClassName} hidden md:flex ${routeActiveStyle('/admin/trips')}`}>Reisen</Link>
            <Link to="/admin/galleries" className={`${navItemClassName} hidden md:flex ${routeActiveStyle('/admin/galleries')}`}>Galerien</Link>
            <Link to="/admin/news" className={`${navItemClassName} hidden md:flex ${routeActiveStyle('/admin/news')}`}>News</Link>
            <button className={`${navItemClassName} hidden md:flex ml-auto`} onClick={logout}>Logout</button>

            {/** Toggle */}
            <button className={"md:hidden ml-auto pr-2"} onClick={() => setMenuIsOpen((prev) => !prev)}>
              <div className={`space-y-2 relative w-8 h-8 ${menuIsOpen ? "mt-2 -mb-2" : ""}`}>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out ${menuIsOpen ? "absolute rotate-45 top-2" : ""}`}></div>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out ${menuIsOpen ? "absolute -rotate-45" : ""}`}></div>
                <div className={`w-8 h-1 bg-white transition duration-200 ease-in-out  ${menuIsOpen ? "absolute -rotate-45" : ""}`}></div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Nav */}
      <div className={`transform ${menuIsOpen ? "translate-none" : "-translate-x-full"} 
        transition duration-200 ease-in-out
        md:hidden 
        absolute h-screen w-5/6 bg-blue-900 
        text-white z-20 shadow-black shadow-lg`}>
        <Link to="/admin/trips" className={`${navItemClassName}`} onClick={() => setMenuIsOpen(false)}>Reisen</Link>
        <Link to="/admin/galleries" className={navItemClassName} onClick={() => setMenuIsOpen(false)}>Galerien</Link>
        <Link to="/admin/news" className={navItemClassName} onClick={() => setMenuIsOpen(false)}>News</Link>
        <Link className={navItemClassName} onClick={logout}>Logout</Link>
      </div>
    </div>
  )
}

export default Headline