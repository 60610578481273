import { Link } from 'react-scroll'

interface Props {
  id: string
  src: string
  name: string
}

const ThumbComponent = ({id, src, name} : Props) => {
  return (
    <div className={`basis-full md:basis-1/4 grow-0 shrink-0 pb-4`}>
      <Link to={id} spy={true} smooth={true} offset={-150} duration={1500} className='cursor-pointer'>

        <div className={`relative group 
          flex flex-col 
          text-center text-white text-3xl sm:text-xl md:text-3xl
          w-full h-auto `}>

          <div className={`rounded-full aspect-square bg-cover border-8 border-white group-hover:blur-sm hover:border-blue-500 
           sm:mx-6 xl:mx-4`}
            style={{ backgroundImage: `url(${src})` }}></div>

          <div className="absolute w-full h-full flex justify-center items-center opacity-0 group-hover:opacity-100 drop-shadow ">
            <span className='text-white'>{name}</span>
          </div>
        </div>

      </Link>
    </div>
  )
}

export default ThumbComponent