import { Link, Outlet, useOutletContext } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

import { useSession as Session } from '../App'
import { SessionContextType } from '../../service/app.types'

const News = () => {
  const { token, setToken, logout } = Session()

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <div className='basis-1/6'>
          <Link to="/admin/news" className='block bg-slate-800 text-white text-sm font-bold py-2 px-4'>
            <span>News</span>
          </Link>
          <Link to="/admin/news" 
            className='items-center items-stretch flex block p-4 hover:bg-slate-400 hover:text-white border-b border-slate-600'>
            <span>Alle News anzeigen</span>
            <span className='text-3xl font-bold ml-auto text-slate-600 -mt-2'>+</span>
          </Link>
          <Link to="/admin/news/new" 
            className='items-center items-stretch flex block p-4 hover:bg-slate-400 hover:text-white border-b border-slate-600'>
            <span>Neue News erstellen</span>
            <span className='text-3xl font-bold ml-auto text-slate-600 -mt-2'>+</span>
          </Link>
        </div>
        <div className='basis-5/6 p-4'>
          <Outlet context={{ token, setToken, logout }} />
        </div>
      </div>
    </div>
  )
}

export function useSession(){
  return useOutletContext<SessionContextType>()
}

export default News