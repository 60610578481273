import { UseFormRegister } from 'react-hook-form'

const errorStyle = `text-sm bg-red-200 rounded p-1 m-1 border border-red-600 text-black block`;

interface Props {
  label: string
  name: string,
  required?: boolean,
  error?: any,
  errorMsg: string,
  register: UseFormRegister<any>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>,
  inputStyle?: string,
}

const TextareaElement = ({ register, label, name, required = false, error, errorMsg, onChange, inputStyle }: Props) => {
  return (
    <div>
      <label htmlFor={name} className='mt-4'>{label}</label>
      <textarea {...register(name, { required: required })}
        className={`${inputStyle} appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow mt-2`} onChange={onChange}/>
      {error && <span className={errorStyle}>{errorMsg}</span>}
    </div>)
}

export default TextareaElement