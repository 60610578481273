import Dropdown, { Option } from 'react-dropdown';

type Props = {
  options: string[],
  onChange: (arg: Option) => void,
  defaultOption: string,
  label: string,
}
const DropdownElement = (props: Props) => {
  return (
    <div>
      <label>{props.label}</label>
      <Dropdown options={props.options} onChange={props.onChange} value={props.defaultOption} placeholder="Bitte wählen Sie eine Option aus!" />
    </div>
  )
}

export default DropdownElement