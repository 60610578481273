import React from 'react'
import { useNavMagic } from './useNavMagic'

interface MyProps {
  className?: string,
 }

const NavMagicContainer = (props: React.PropsWithChildren<MyProps>) => {
  const { navHeight } = useNavMagic()

  return (
    <div style={{ height: `${navHeight-1}px` }} className={`${props.className ?? 'bg-green-400'}`}>
      {props.children}
    </div>
  )
}

export default NavMagicContainer