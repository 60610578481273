import { useState } from "react"
import { IButton } from "../../service/app.types"
import RadioButton from "./RadioButton"

type Props = {
  buttons: IButton[],
  onChange: (key: number) => void,
  className: string,
}

const defaultColor = "text-black"

const RadioButtonGroup = (props : Props) => {

  const [isActive, setIsActive] = useState(props.buttons.findIndex(b => b.isDefault) ?? 0)

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    try{
      const key = parseInt((e.target as HTMLButtonElement).value)
      setIsActive(key)

      props.onChange(key);
    }
    catch(err){
      return;
    }
  }

  return (
    <div className={`${props.className}`}>
      {
        props.buttons.map((button, index) => {
          return <RadioButton activeColor={button.activeColor ?? defaultColor} isActive={isActive === index} key={index} value={index} label={button.label} onClick={onClick} />
        })
      }
    </div>
  )
}

export default RadioButtonGroup