import { Image } from '../../service/dto.types'

type Props = {
  image: Image,
  handleDeleteImage?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  handleSetAsTitle?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  isLoading?: boolean,
}

const GalleryImage = ({ image, handleDeleteImage, handleSetAsTitle, isLoading = false }: Props) => {
  return (
    isLoading ?
    <div className="basis-1/4">
      <div className="m-4 w-48 overflow-hidden relative">
        <div className="h-32 w-full object-cover object-center bg-gray-300 animate-pulse" />
      </div>
    </div>
    :
    <div className="basis-1/4">
      <div key={image.id} className="m-4 w-48 overflow-hidden relative">
        <img src={image.thumb} alt="Bild zur Gallerie" className='h-32 w-full object-cover object-center' />

        {
        // Delete image
          <button type="button" className='absolute top-0 right-0 bg-red-600/75 hover:bg-red-800' onClick={handleDeleteImage} data-id={image.id}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0,0,16,16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
            </svg>
          </button>
        }

        {
        // Set as Title
          <button type="button" className='absolute top-0 right-0 bg-red-600/75 hover:bg-red-800' onClick={handleSetAsTitle} data-id={image.id}>
          T
          </button>
        }

      </div>
    </div>
    )
}

export default GalleryImage