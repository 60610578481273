import HomeScreen from './components/HomeScreen'
import Home from './components/Home'
import Footer from './components/Footer'
import Nav from './components/Nav'

import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import ContactForm from './components/ContactForm';

import "./App.css"

function App() {

  const location = useLocation();

  const homeRef = useRef<HTMLDivElement | null>(null)
  const homeScreenRef = useRef<HTMLDivElement | null>(null)

  const [showMenu, setShowMenu] = useState(false)
  let [showContactForm, setShowContactForm] = useState(false)

  const executeScroll = () => {
    homeRef.current !== null && homeRef.current.scrollIntoView({ behavior: "smooth" }) 
  }   

  /* show menu if scrolled down enough */
  const handleScroll = () => {
    if (homeScreenRef.current != null) {
      const position = window.scrollY;
      setShowMenu(position > homeScreenRef.current.clientHeight || (window.innerHeight + window.scrollY) >= document.body.offsetHeight)
    }
  };

  
  useEffect(() => {
    if (location.state && location.state.showTrips) {
      setShowMenu(true)
      executeScroll()
    }
    else {
      handleScroll();
    }

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, [location]);
  

  return (
     <div className='bg-white'>
      
      <Nav showMenu={showMenu} />

      <HomeScreen executeScroll={executeScroll} ref={homeScreenRef} />

      {/** Content that is shown after scrolling down */}
      <Home ref={homeRef} />
      
      <Footer setShowContactForm={setShowContactForm}/>

      <ContactForm set={setShowContactForm} show={showContactForm} />
    </div>
  );
}

export default App;
