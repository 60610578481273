import { useEffect, useReducer } from 'react'
import { Trip } from './service/dto.types'
import { Style } from "./Globals"

import { ACTIONS, IState, IAction, parseTrip, groupTrips, isPastTrip, isAbgesagt } from "./service/trip.service"
 
import * as ApiService from "./service/api.service"
import NavMagicContainer from './NavMagicContainer'

const PastTrips = () => {

  const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
      case ACTIONS.SET_TRIPS:
        return { ...state, trips: action.payload.trips, tripsLoaded: true }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    trips: [] as Trip[],
    tripsLoaded: false,
  })

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_TRIPS_LOADED, payload: { tripsLoaded: false } })

    /* API load trips */
    ApiService.fetchTrips().then((fetchedTrips) => {
      let trips = fetchedTrips.map(trip => parseTrip(trip)).filter(t => isPastTrip(t) && !isAbgesagt(t)) // Parse and filter
      dispatch({ type: ACTIONS.SET_TRIPS, payload: { trips: trips } })
    })
  }, [])

  return (
    <div>
      <NavMagicContainer />

      <div className="container m-auto px-4 py-16 text-center">
      <h1 className={`${Style.Title} text-center`}>Vergangene Reisen</h1>
      <div className="italic p-6">"Momente vergehen, doch Erinnerungen bleiben."</div>

      {
        state.tripsLoaded &&
          (
            state.trips && state.trips.length > 0 ?
              <div>
                            
              {
                Object.keys(groupTrips(state.trips)).map((month) => {
                  return (
                    <div key={month}>
                      <h2 className={`${Style.SubTitle} p-4 mt-8`}>im <strong>{month}</strong></h2>
                      <div className="flex flex-rows flex-wrap text-center">
                        {
                          state.trips &&
                          groupTrips(state.trips)[month].map((trip) => {
                            return (
                              <div className="basis-full">
                                {trip.title}
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                }) 
              }
              </div>
          : <div className="flex justify-center text-2xl text-slate-500 font-gili ">
              <span className="p-4 mt-8 rounded border border-slate-500">Keine Reisen vorhanden!</span>
            </div>
          )
      }
    </div>
    </div>
  )
}

export default PastTrips