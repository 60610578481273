import { ReactComponent as PlusIcon } from '../icons/plus-icon.svg'
import { useState, useEffect } from 'react'

enum Colors {
  Pink = "border-pink-500 hover:bg-pink-500 text-pink-500",
  Blue = "border-blue-700 hover:bg-blue-700 text-blue-700",
}

interface Props {
  message: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
  color?: string,
  className?: string,
  id?: string,
}

const ButtonAdd = (props : Props) => {
  let [color, setColor] = useState(Colors.Blue)

  useEffect(() => {
    switch(props.color){
      case "blue":
        setColor(Colors.Blue)
        break;
      default:
        setColor(Colors.Blue)
    }
  }, [props.color])
  return (
    <button 
      className={`${props.className} flex items-center mt-4 py-2 px-4 rounded-full border-2 text-lg 
      hover:text-white ${color}`}
      onClick={props.onClick}
      id={props.id}
      >
      {props.message}
      
      <PlusIcon className='w-6 inline-block ml-2' />
    </button>  
    )
}

export default ButtonAdd
