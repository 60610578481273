import Trips from './Trips'
import News from './News'
import { Element } from 'react-scroll'
import ArrowUp from './ui/ArrowUp'
import { forwardRef } from 'react'

type Props = {

}
const Home = forwardRef((props : Props, ref : React.LegacyRef<HTMLDivElement>) => {

  return (
    <div className="min-h-screen -scroll-mt-2" ref={ref}>
      
      <ArrowUp to="tripOverview" />

      <Element name="tripOverview"  />
      {/* Trips Component */}
      <Trips />

      {/* News Component */}
      <News />

    </div>
  )
})




export default Home
