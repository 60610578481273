import { useEffect, useReducer, useRef } from 'react'
import { Style } from "./Globals.js"
import { useForm } from "react-hook-form";
import ButtonContinue from './ui/buttons/ButtonContinue';
import ButtonAdd from './ui/buttons/ButtonAdd';
import Button from './ui/buttons/Button';
import ButtonBack from './ui/buttons/ButtonBack';
import Message from './ui/Message';
import ButtonClose from './ui/buttons/ButtonClose';
import ShortUniqueId from 'short-unique-id'
import CaptchaElement from './ui/form/CaptchaElement'
import { sendEmail } from './service/api.service'

// Form elements
import Input from './ui/form/InputElement';
import Textarea from './ui/form/TextareaElement';

import { getDateRange, parseTrip } from './service/trip.service'
import { fetchTripById } from './service/api.service'
import { Trip } from './service/dto.types'

import "./BookingForm.css"
import { useNavMagic } from './useNavMagic';
import RadioButtonGroup from './ui/buttons/RadioButtonGroup';
import { IButton } from './service/app.types.js';
import PhoneInput from './ui/form/PhoneInput';
import DatePickerElement from './ui/form/DatePickerElement2';

interface Traveller {
  id: string,
  firstName: string,
  lastName: string,
  phone: string,
  birthday: string,
  email?: string,
  address?: string,
  plz?: string,
  city?: string,
}

export interface Booking {
  travellers: Traveller[],
  remarks: string,
  tripId: number,
  contactPerson?: string,
  insurance: string,
  captcha: string,
}

interface Props {
  tripId: number,
  onClose: React.MouseEventHandler<HTMLButtonElement>,
}

const buttons = [
  {
    label: "Ja, ich möchte eine Reiseversicherung!",
    activeColor: "text-green-500 hover:text-green-600",
  },
  {
    label: "Nein, ich möchte keine Reiseversicherung!",
    activeColor: "text-red-500 hover:text-red-600",
    isDefault: true,

  },
  {
    label: "Weiss nicht.",
    activeColor: "text-blue-500 hover:text-blue-600",
  },
] as IButton[]


const initialBooking: Booking = {
  travellers: [],
  remarks: "",
  tripId: -1,
  insurance: buttons.find(b => b.isDefault)?.label ?? buttons[0].label,
  captcha: "",
}

enum Pages {
  Page1,
  Page2,
  Page3
}

const uid = new ShortUniqueId({ length: 10 });

const getNewTraveller = (): Traveller => {
  return {
    id: uid.rnd(),
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    birthday: "",
  };
}

const ACTIONS = {
  SET_BOOKING: 'SET_BOOKING',
  SET_BOOKING_IS_COMPLETED: 'SET_BOOKING_IS_COMPLETED',
  SET_PAGE: 'SET_PAGE',
  SET_SHOW_ADD_PERSON: 'SET_SHOW_ADD_PERSON',
  SET_CURRENT_PERSON: 'SET_CURRENT_PERSON',
  SET_TRIP: 'SET_TRIP',
  SET_SHOW_ADDRESS_BOX: 'SET_SHOW_ADDRESS_BOX',
  SET_TRAVELLERS: 'SET_TRAVELLERS',
  SET_INSURANCE: 'SET_INSURANCE',
}

type FieldValues = {
  remarks: string,
  captcha: string,
}

const BookingForm = (props: Props) => {

  const myRef = useRef<HTMLDivElement | null>(null)
  const { navHeight } = useNavMagic()
  const { control, register, handleSubmit, formState, reset } = useForm<Traveller>();
  const { register: register2, formState: formState2, handleSubmit: handleSubmit2 } = useForm<FieldValues>();

  useEffect(() => {
    fetchTripById(props.tripId.toString()).then((t) => {
      if (t !== undefined) {
        try {
          dispatch({ type: ACTIONS.SET_TRIP, payload: { trip: parseTrip(t) } })
        }
        catch (err) {
          console.log(err)
        }
      }
    })
  }, [props.tripId])

  const getContactPerson = (state: IState): string => {
    if (state !== undefined) {
      let traveller = state.booking.travellers.find(t => t.address !== undefined) ?? state.booking.travellers.at(0)
      if (traveller !== undefined) {
        return `${traveller.firstName} ${traveller.lastName}`
      }
    }

    return "Keine Angabe"
  }

  /* START -- use Reducer */

  interface IState {
    booking: Booking,
    bookingIsCompleting: boolean,
    bookingIsCompleted: boolean,
    page: Pages,
    showAddPerson: boolean,
    currentPerson: Traveller,
    trip: Trip,
    showAddressBox: boolean,
    insurance: string,
    errorMessage: string,
    success: boolean,
  }

  const defaultState: IState = {
    booking: initialBooking,
    bookingIsCompleting: false,
    bookingIsCompleted: false,
    page: Pages.Page2,
    showAddPerson: false,
    currentPerson: getNewTraveller(),
    trip: {} as Trip,
    showAddressBox: false,
    insurance: buttons.find(b => b.isDefault)?.label ?? buttons[0].label,
    errorMessage: "",
    success: false,
  }

  interface IAction {
    type: string;
    payload: any;
  }

  const scrollIntoView = () => {
    myRef.current !== null && myRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const reducer = (state: IState, action: IAction): IState => {

    switch (action.type) {
      case ACTIONS.SET_BOOKING:
        return {
          ...state,
          booking: action.payload.booking,
          showAddPerson: false
        }
      case ACTIONS.SET_BOOKING_IS_COMPLETED:
        action.payload.bookingIsCompleted && scrollIntoView()

        return {
          ...state,
          bookingIsCompleting: action.payload.bookingIsCompleting ?? state.bookingIsCompleting,
          bookingIsCompleted: action.payload.bookingIsCompleted ?? state.bookingIsCompleted,
          errorMessage: action.payload.errorMessage ?? state.errorMessage,
          success: action.payload.success ?? state.success,
        }
      case ACTIONS.SET_PAGE:
        scrollIntoView()

        return {
          ...state,
          page: action.payload.page,
          booking: {
            ...state.booking,
            contactPerson: action.payload.page === Pages.Page3 ? getContactPerson(state) : state.booking.contactPerson
          }
        }
      case ACTIONS.SET_SHOW_ADD_PERSON:
        scrollIntoView()

        return { ...state, showAddPerson: action.payload.showAddPerson }
      case ACTIONS.SET_CURRENT_PERSON:
        scrollIntoView()

        reset(action.payload.currentPerson)

        return {
          ...state,
          currentPerson: action.payload.currentPerson,
          showAddPerson: action.payload.showAddPerson !== undefined ? action.payload.showAddPerson : true,
          showAddressBox: state.booking.travellers.every(t => !t.address && !t.plz && !t.city)
            || !!action.payload.currentPerson.address || !!action.payload.currentPerson.city || !!action.payload.currentPerson.plz
        }
      case ACTIONS.SET_TRIP:
        scrollIntoView()

        return {
          ...state,
          trip: action.payload.trip,
          booking: {
            ...state.booking, tripId: (action.payload.trip as Trip).id ?? -1
          }
        }
      case ACTIONS.SET_SHOW_ADDRESS_BOX:
        scrollIntoView()

        return { ...state, showAddressBox: action.payload.showAddressBox }
      case ACTIONS.SET_TRAVELLERS:
        scrollIntoView()

        return { ...state, booking: { ...state.booking, travellers: action.payload.travellers } }
      case ACTIONS.SET_INSURANCE:
        return { ...state, insurance: action.payload.insurance }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, defaultState)

  /* END -- use Reducer*/

  /* handlers */
  const handleInsuranceChange = (key: number) => {
    dispatch({ type: ACTIONS.SET_BOOKING, payload: { booking: { ...state.booking, insurance: buttons[key].label } } })
  }

  const remarksChangedHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      type: ACTIONS.SET_BOOKING, payload: {
        booking: {
          ...state.booking, remarks: e.currentTarget.value
        }
      }
    })
  }

  const handleShowOverview = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: ACTIONS.SET_SHOW_ADD_PERSON, payload: { showAddPerson: false } })
  }

  const handleAddPerson = () => {
    dispatch({ type: ACTIONS.SET_CURRENT_PERSON, payload: { currentPerson: getNewTraveller() } })
  }

  const onSubmit = (data: Traveller) => {
    let traveller = data

    dispatch({
      type: ACTIONS.SET_BOOKING, payload: {
        booking: {
          ...state.booking,
          travellers: state.booking.travellers.some(t => t.id === state.currentPerson.id) ?
            state.booking.travellers.filter(t => t.id !== state.currentPerson.id).concat(traveller)
            : state.booking.travellers.concat(traveller)
        } as Booking
      }
    })

    scrollIntoView()

    reset(getNewTraveller())
  }
  const onSubmit2 = (data: FieldValues) => {



    dispatch({
      type: ACTIONS.SET_BOOKING_IS_COMPLETED, payload: {
        bookingIsCompleting: true,
        errorMessage: "",
      }
    })

    sendEmail({
      ...state.booking,
      remarks: state.booking.remarks !== "" ? state.booking.remarks : "Keine Bemerkungen.",
      captcha: data.captcha,
      travellers: state.booking.travellers.map(t => { return { ...t, birthday: new Date(t.birthday).toLocaleDateString("de-DE") } })
    })
      .then((res) => {
        dispatch({
          type: ACTIONS.SET_BOOKING_IS_COMPLETED, payload: {
            bookingIsCompleting: false,
            bookingIsCompleted: true,
            success: true,
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.SET_BOOKING_IS_COMPLETED, payload: {
            bookingIsCompleting: false,
            bookingIsCompleted: false,
            success: false,
            errorMessage: error.message === "Invalid captcha" ? "Bitte geben Sie die korrekte Lösung der Rechnung ein." : error.message,
          }
        })
      })
  }



  /* Components */

  const ButtonBackComponent = (props: { onClick: (e: React.MouseEvent<HTMLButtonElement>) => void }) => {
    return <ButtonBack onClick={props.onClick} message="zurück" className="pl-4 pt-3 absolute left-0 top-0" />
  }


  /* Teilnehmerliste */
  const TeilnehmerListe = () => <div className='flex flex-col flex-wrap rounded mt-8'>
    <h2>Teilnehmerliste:</h2>
    {
      state.booking.travellers.map((t, index) => {
        return (
          <div className='flex flex-row items-center justify-left' key={t.id}>
            <div>
              <button onClick={() => {
                dispatch({ type: ACTIONS.SET_CURRENT_PERSON, payload: { currentPerson: t } })
              }} className='uppercase tracking-wide font-bold'>{t.firstName} {t.lastName}</button>
            </div>
            <div>
              <ButtonClose onClick={() => {
                dispatch({
                  type: ACTIONS.SET_TRAVELLERS, payload: {
                    travellers: state.booking.travellers.filter(traveller => traveller.id !== t.id)
                  }
                })
              }} />
            </div>
          </div>
        )
      })
    }
  </div>


  const Page2 = (
    <div>
      {
        state.showAddPerson ?
          <h2 className={`${Style.SubTitle} my-4`}>Reiseteilnehmende registrieren</h2>
          : <h2 className={`${Style.SubTitle} my-4`}>Reisebuchung</h2>
      }

      {
        state.showAddPerson &&
        <div>
          <ButtonBackComponent onClick={handleShowOverview} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col flex-wrap text-left space-y-4 mt-4">

              <Input name="firstName" label="Vorname" register={register}
                required={true}
                placeholder="Bitte geben Sie einen Vornamen ein"
                error={formState.errors.firstName}
                errorMsg="Bitte geben Sie einen Vornamen ein"
              />

              <Input name="lastName" label="Nachname" register={register}
                required={true}
                placeholder="Bitte geben Sie einen Nachnamen ein"
                error={formState.errors.lastName}
                errorMsg="Bitte geben Sie einen Nachnamen ein" />

              <PhoneInput
                name="phone"
                control={control}
                placeholder="Ihre Telefonnummer"
                error={formState.errors.phone}
                errorMsg='Bitte geben Sie eine gültige Telefonnummer ein!'
                required={true}
              />

              <Input name="email" label="E-Mail" register={register}
                required={true}
                placeholder="Bitte geben Sie eine E-mail Adresse ein"
                error={formState.errors.email}
                errorMsg="Bitte geben Sie eine E-mail Adresse ein"
                type='email'
              />

              {
                <DatePickerElement
                  name="birthday"
                  label="Geburtsdatum"
                  required={true}
                  control={control}
                  defaultValue={state.currentPerson.birthday}
                  placeholder="Bitte geben Sie ein Geburtsdatum ein"
                  error={formState.errors.birthday}
                  errorMsg='Bitte geben sie ein gültiges Geburtsdatum ein!'
                />
              }

              {
                state.showAddressBox ?
                  <div>
                    {
                      /* Adresse */
                      <Input name="address" label="Adresse" register={register}
                        registerOptions={{
                          required: state.booking.travellers.every(t => !t.address && !t.plz && !t.city),
                        }}                        
                        placeholder='Bitte geben Sie eine Adresse ein'
                        error={formState.errors.address}
                        errorMsg="Bitte geben Sie eine Adresse ein" />
                    }

                    <div className='flex flex-wrap md:flex-nowrap flex-col md:flex-row md:space-x-2'>
                      {
                        /* PLZ */
                        <Input className="basis-1/4 mt-4" name="plz" label="PLZ" register={register}
                          registerOptions={{
                            required: state.booking.travellers.every(t => !t.address && !t.plz && !t.city),
                            pattern: {
                              value: /^[0-9]+$/,
                              message: 'Bitte geben Sie einen numerischen Wert ein',
                            } 
                          }}
                          placeholder='PLZ'
                          error={formState.errors.plz}
                          errorMsg="Bitte geben Sie einen PLZ ein"
                        />
                      }

                      {
                        /* Ort */
                        <Input name="city" label="Ort" register={register}
                          registerOptions={{
                            required: state.booking.travellers.every(t => !t.address && !t.plz && !t.city),
                          }}
                          placeholder='Bitte geben Sie einen Ort ein'
                          error={formState.errors.city}
                          errorMsg="Bitte geben Sie einen Ort ein"
                          className='grow mt-4' />
                      }

                    </div>
                  </div>
                  :
                  state.booking.travellers.some(t => t.address !== undefined && t.plz !== undefined && t.city !== undefined && t.id !== state.currentPerson.id) &&
                  <div className='flex justify-end'>
                    <ButtonAdd color="blue" message="Addresse eingeben (freiwillig)" onClick={(e) => dispatch({ type: ACTIONS.SET_SHOW_ADDRESS_BOX, payload: { showAddressBox: true } })} />
                  </div>
              }
            </div>

            <div className='flex justify-end mt-4'>
              <div>
                <Button type="submit" message="Person speichern" />
              </div>
            </div>
          </form>
        </div>
      }

      <div>

        {
          state.booking.travellers.length > 0 ?
            <TeilnehmerListe />
            : !state.showAddPerson && <div>Bitte fügen Sie eine Person hinzu.</div>
        }


        <div className={`flex flex-cols md:flex-row flex-wrap ${state.booking.travellers.length > 0 ? "justify-between" : "justify-center"}`}>
          {
            state.showAddPerson ||
            <div>
              <ButtonAdd message="Person hinzufügen" onClick={handleAddPerson} />
            </div>
          }

          {
            state.booking.travellers.length > 0 &&
            <ButtonContinue color="green" message="Fortfahren" onClick={() => setPage(Pages.Page3)} />
          }
        </div>


      </div>
    </div>
  )

  const OverviewElement = ({ title, value }: { title: string, value?: string }) => {
    return (
      <div className='flex flex-row flex-wrap justify-between mt-4 border-b border-white text-xl'>
        <div>{title}</div>
        <div>{value}</div>
      </div>
    )
  }

  const setPage = (page: Pages) => {
    dispatch({ type: ACTIONS.SET_PAGE, payload: { page: page } })
  }



  const Page3 = (
    <div className='flex flex-col flex-wrap'>
      <ButtonBackComponent onClick={() => setPage(Pages.Page2)} />

      <h2 className={`${Style.SubTitle} my-4`}>Reservation abschliessen</h2>

      {
        /* Reservation overview */
      }
      <OverviewElement title="Reise" value={!state.trip ? "Keine Angabe" : state.trip.title} />
      <OverviewElement title="Datum" value={!state.trip ? "Keine Angabe" : getDateRange(state.trip)} />
      <OverviewElement title="Anzahl Teilnehmer" value={state.booking.travellers.length.toString()} />
      <OverviewElement title="Kontaktperson" value={state.booking.contactPerson} />

      <div className='mt-8'>

        <form onSubmit={handleSubmit2(onSubmit2)}>

          
          <Textarea register={register2} name="remarks"
            label="Bemerkungen (Sitzplatz, Verpflegung, Allergien, etc.)"
            errorMsg='Bitte geben Sie einen gültigen Wert ein' error={formState2.errors.remarks}
            onChange={remarksChangedHandler}
            inputStyle="rounded-md"
          />

          <div className='mt-8'>
          <h2 className='text-md font-bold mb-2'>Reiseversicherung</h2>

            <label>Möchten Sie eine Reiseversicherung abschliessen? </label>
            <RadioButtonGroup buttons={buttons} onChange={handleInsuranceChange} className="mt-2 flex flex-col flex-wrap" />
          </div>

          <div>
            <h2 className='text-md font-bold mt-4 mb-2'>Datenschutz</h2>
            Betreffend Verarbeitung Ihrer Daten, die bei Buchung an uns übermittelt werden, können Sie unsere <a href="/privacy" target="_blank" rel="noreferrer" className='underline'>Datenschutzerklärung</a> einsehen.
          </div>

          <h2 className='text-md font-bold mt-8 mb-2'>Captcha</h2>

          <CaptchaElement
            label="Um sicher zu gehen, dass Sie kein Roboter sind, geben Sie bitte die Lösung dieser Rechnung ein:"
            name="captcha"
            errorMsg="Bitte geben Sie die Lösung dieser Rechnung ein"
            error={formState2.errors.captcha}
            required={true}
            register={register2}
          />


          {
            state.booking.travellers.length > 0 && state.trip !== undefined &&
            <div className='mt-auto flex justify-center pt-8'>
              <Button message="Reservation abschliessen" type="submit" disabled={state.bookingIsCompleting} className={state.bookingIsCompleting ? 'loading' : ''} />
            </div>
          }

          {
            !state.success && state.errorMessage !== "" &&
            <div className='text-red-500 bg-red-100 border-red border-1 rounded p-2 mt-4'>
              {state.errorMessage}
            </div>
          }
        </form>
      </div>
    </div>
  )


  /* RENDER */

  return (
    <div ref={myRef}
      className={`relative flex justify-center text-left xl:w-1/2 xl:w-1/3 m-auto mt-4`}
      style={{ scrollMarginTop: `${navHeight + 14}px` }}
    >
      <ButtonClose onClick={props.onClose} className='absolute right-0' />
      {state.booking &&
        <div className={`w-full rounded-md shadow-lg p-8`}>
          {
            state.bookingIsCompleted ?
              <Message
                title="Vielen Dank für Ihre Reservation"
                message="Wir werden uns in Kürze bei Ihnen melden." />
              :
              <div className='flex flex-col flex-wrap'>
                {
                  state.page === Pages.Page2 ? Page2
                    : state.page === Pages.Page3 ? Page3
                      : Page2
                }
              </div>
          }
        </div>
      }
    </div>
  )
}

export default BookingForm