import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNewGallery } from "./service/gallery.service";
import { fetchGalleryById } from "./service/api.service";
import NavMagicContainer from "./NavMagicContainer";
import { Style } from "./Globals";
import { PhotoAlbum } from "react-photo-album"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"

interface SrcPhoto {
  thumb: string,
  id: number,
  height: number,
  width: number,
}

const Gallery = () => {

  const [index, setIndex] = useState(-1 as number);

  const { galleryId } = useParams();

  const [gallery, setGallery] = useState(getNewGallery());
  useEffect(() => {
    if (!galleryId) return;
    fetchGalleryById(galleryId).then((data) => {
      setGallery(data);
    }).catch((e) => {
      console.log(e);
    });
  }, [galleryId])

  return (
    <div>
      <NavMagicContainer />
      <h1 className={`${Style.Title} pt-16 text-center`}>{gallery.title}</h1>
      <p>{gallery.description}</p>
      <div className="py-8">
        <PhotoAlbum
          layout="masonry"
          photos={(gallery.images?.filter(i => i.thumb !== undefined) as SrcPhoto[]).map(i => {
            return ({
              id: 5,
              src: i.thumb,
              width: 100,
              height: 200,
            })
          }) ?? []}
          targetRowHeight={150}
          onClick={({ index: current }) => setIndex(current)}
        />

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => {
            // reset indexes
            setIndex(-1)
          }}
          slides={gallery.images?.map((image) => {
            return {
              src: image.src ?? ""
            }
          })}
        />
      </div>
    </div>
  )
}

export default Gallery